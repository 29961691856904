

<div *ngIf="shopItem" class="flex flex-column">
  <div>
    <span *ngIf="shopItem.archived">
      ({{ 'archived' | translate }})
    </span>
    <span class="font-bold">
      {{ shopItem.title }}
    </span>
  </div>

  <div class="flex flex-wrap">

    <div *ngIf="shopItem as si" class="details-block-wrap ">
      <span *ngIf="siHelper.getSessionGroupDate(si.sessionGroup) as sgDate" class="detail-block">
        {{ sgDate | translate }}
      </span>
  
      <span *ngIf="siHelper.getSessionGroupTime(si.sessionGroup) as sgTime" class="detail-block">
        {{ sgTime }}
      </span>
  
      <span *ngIf="siHelper.getSessionGroupLocation(si.sessionGroup, locationUseInternalTitle) as sgLocation" class="detail-block">
        <span [ngbTooltip]="sgLocation" class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
          {{ sgLocation }}
        </span>
      </span>
    </div>

    <span *ngFor="let item of info; let i = index">
      {{ item }}<ng-container *ngIf="i < info.length -1 ">&nbsp;|&nbsp;</ng-container>
    </span>
  </div>
</div>
