



export enum Currency {
  CZK = 'CZK',
  EUR = 'EUR'
};

export const CURRENCIES: Currency[] = Object.values(Currency);
