

@if (reservation; as r) {
  
  <div class="row">
    <span class="font-bold" [ngbTooltip]="discountedPriceTooltip">
      {{ discountedPrice | price | async }}
    </span>
  </div>
  
  <div class="row">
    <span class="text-xs" [ngbTooltip]="basePriceTooltip">
      @if (r.order && r.orderItem) {
        {{ (([{ value: r.orderItem.basePriceValue, currency: r.orderItem.currency }]) | price | async) | priceCount:r.count | async }}
      } @else {
        {{ ((r.shopItem?.price ?? []) | price | async) | priceCount:r.count | async }}
        <app-currency-changer
          [price]="r.shopItem?.price ?? []"
          [type]="CurrencyChangerType.ICON"
          [appendTo]="'body'">
        </app-currency-changer>
      }
    </span>
  </div>

}

<ng-template #basePriceTooltip>
  <div class="text-left">
    {{ 'admin.reservations.table.cell.price-base.tooltip' | translate }}
  </div>
</ng-template>

<ng-template #discountedPriceTooltip>
  <div class="text-left">
    <div>
      {{
        vat ? ('admin.reservations.table.cell.price-vat.tooltip' | translate) : ('admin.reservations.table.cell.price-no-vat.tooltip' | translate)
      }}
      {{
        discountsStrings.length ? ('admin.reservations.table.cell.price-after-discounts-label.tooltip' | translate) : ''
      }}
    </div>
    @if (vat) {
      <div>
        {{ ('admin.reservations.table.cell.price-without-vat.tooltip' | translate:{price: discountedPriceWithoutVat | price | async  }) }}
      </div>
    }
    <div>
      @for (d of discountsStrings; track d) {
        <div>
          • {{ d }}
        </div>
      }
    </div>
    @if (additionalDiscountsString) {
      <div>
        {{ additionalDiscountsString | translate }}
      </div>
    }
  </div>
</ng-template>

<!-- {{
    discountsStrings
      ? vat
        ? ('admin.reservations.table.cell.price-vat.tooltip' | translate:{ discounts: discountsString, price: discountedPriceWithoutVat | price | async }) + (additionalDiscountsString | translate)
        : ('admin.reservations.table.cell.price-no-vat.tooltip' | translate:{ discounts: discountsString + (additionalDiscountsString | translate) }) + (additionalDiscountsString | translate)
      : additionalDiscountsString ? (additionalDiscountsString | translate) : null
}} -->