import { Injectable, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, fromEvent } from 'rxjs';
import screenfull from 'screenfull';

export enum NetworkStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
};
export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop'
};
export enum DeviceSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
};
export const DEFAULT_DEVICE_SIZE = DeviceSize.LG;

@Injectable({
  providedIn: 'root'
})
export class SystemService implements OnDestroy {

  public deviceType$: BehaviorSubject<DeviceType> = new BehaviorSubject<DeviceType>(DeviceType.DESKTOP);
  public deviceSize$: BehaviorSubject<DeviceSize> = new BehaviorSubject<DeviceSize>(DEFAULT_DEVICE_SIZE);
  public deviceWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  networkStatus$ = new BehaviorSubject<NetworkStatus>(NetworkStatus.ONLINE);

  public newAppVersion$ = new BehaviorSubject<boolean>(false);

  public get fullscreenEnabled() { return screenfull.isEnabled; };
  public get isFullscreen() { return screenfull.isFullscreen; };

  resizeListenerFnc = () => {
    this.handleDeviceSize();
  };

  constructor(
    private deviceService: DeviceDetectorService,
  ) {

    fromEvent(window, 'online').subscribe((n) => this.networkStatus$.next(NetworkStatus.ONLINE));
    fromEvent(window, 'offline').subscribe((n) => this.networkStatus$.next(NetworkStatus.OFFLINE));

    window.addEventListener('resize', this.resizeListenerFnc);
    this.handleDeviceSize();
    this.handleDeviceType();
  }

  public isInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public appendScript(id: string, src: string) {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.id = id;
    s.src = src;
    s.async = true;
    s.defer = true;
    document.getElementsByTagName('head')[0].appendChild(s);
  }
  public removeScript(id: string) {
    document.getElementById(id)?.remove();
  }

  private handleDeviceSize() {
    const w = window.innerWidth;
    this.deviceWidth$.next(w);
    if (w < 576) {
      this.deviceSize$.next(DeviceSize.SM);
    } else if (w < 992) {
      this.deviceSize$.next(DeviceSize.MD);
    } else if (w < 1200) {
      this.deviceSize$.next(DeviceSize.LG);
    } else if (w >= 1200) {
      this.deviceSize$.next(DeviceSize.XL);
    }
  }

  private handleDeviceType() {
    this.deviceService.getDeviceInfo()
    this.deviceType$.next(<DeviceType>this.deviceService.deviceType);
  }


  public copyTextToClipboard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  // Function to toggle fullscreen on and off
  toggleFullscreen(element: Element) {
    if (screenfull.isEnabled) {
      screenfull.toggle(element);
    }
  }

  



  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeListenerFnc);
  }
}
