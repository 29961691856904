

<app-modal-header
  (close)="close()">
  <span slot="title" [class]="data.titleClass">
    {{ data.title | translate:data.titleTranslationData }}
  </span>
</app-modal-header>

<div class="modal-body" style="white-space: pre-line;">
  <p>{{ data.text | translate:data.textTranslationData }}</p>


  <ng-container *ngIf="data.template">
    <ng-container *ngTemplateOutlet="data.template"></ng-container>
  </ng-container>
</div>

<div class="modal-footer flex justify-content-center">
  @if (data.buttonMode === BasicModalMode.ONE_BUTTON) {
    <div>
      <div [class]="$any(data).btnWrapClass">
        <button (click)="onResult(BasicModalResult.LEFT, $any(data).btnAction)" pButton label="{{ $any(data).btnLabel | translate:$any(data).btnLabelData }}" [class]="$any(data).btnClass"></button>
      </div>
    </div>
  } @else {
    <div [class]="$any(data).leftBtnWrapClass">
      <button (click)="onResult(BasicModalResult.LEFT, $any(data).leftBtnAction)" pButton label="{{ $any(data).leftBtnLabel | translate:$any(data).leftBtnLabelData }}" [class]="$any(data).leftBtnClass"></button>
    </div>
    <div [class]="$any(data).rightBtnWrapClass">
      <button (click)="onResult(BasicModalResult.RIGHT, $any(data).rightBtnAction)" pButton label="{{ $any(data).rightBtnLabel | translate:$any(data).rightBtnLabelData }}" [class]="$any(data).rightBtnClass"></button>
    </div>
  }

</div>
