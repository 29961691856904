import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { collapseAnimation } from '../../animations/collapse.animation';
import { IOrderFE } from '../../models/order/order-model';
import { OrderState } from '../../enums/order/order-states.enum';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';
import { ReservationState } from '../../enums/reservation/reservation-states.enum';
import { PricePipe } from '../../pipes/price.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ShopItemHelperService } from '../../services/helpers/shop-item-helper.service';
import { ItemTitlePipe } from '../../pipes/item-title.pipe';
import { PriceCountPipe } from '../../pipes/price-count.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderHelperService } from '../../services/helpers/order-helper.service';
import { UtilsService } from '../../services/utils.service';
import { OrgUserNameOrEmailPipe } from '../../pipes/org-user-name-or-email.pipe';
import { TranslateCustomLabelPipe } from '../../pipes/translate-custom-label.pipe';
import { ITranslationObject } from '../../models/shop-item/shop-item-custom-settings.model';

@Component({
  selector: 'app-order-list-item',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    LocalizedDatePipe,
    PricePipe, OrgUserNameOrEmailPipe, ItemTitlePipe, PriceCountPipe,
    NgbTooltipModule, TranslateCustomLabelPipe
  ],
  templateUrl: './order-list-item.component.html',
  styleUrls: ['./order-list-item.component.scss'],
  animations: [ collapseAnimation ]
})
export class OrderListItemComponent {
  OrderState = OrderState;
  ReservationState = ReservationState;

  @Input() order: IOrderFE | undefined;
  @Input() collapsed: boolean = true;
  @Input() set collapse(val: boolean) {
    if (val) this.collapsed = true;
  }
  @Input() customOrderStateLabel?: ITranslationObject | null;

  constructor(
    public siHelper: ShopItemHelperService,
    public orderHelper: OrderHelperService,
    private utilsService: UtilsService
  ) { }

  onItem() {
    this.collapsed = !this.collapsed;
  }


  missingStateTemplate(state: OrderState) {
    this.utilsService.logError(`Missing template for state: ${state} in OrderListItemComponent`);
  }
}
