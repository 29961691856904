import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CurrencyChangerComponent, CurrencyChangerType } from 'src/app/shared/components/currency-changer/currency-changer.component';
import { IReservationInvitation } from 'src/app/shared/models/reservation-invitation/reservation-invitation.model';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { ReservationTableEntityType } from 'src/app/shared/services/store/org-reservations-store.service';

@Component({
  selector: 'app-price-cell-invitation-entity',
  standalone: true,
  imports: [
    PricePipe, AsyncPipe, 
    CurrencyChangerComponent,
  ],
  templateUrl: './price-cell-invitation-entity.component.html',
  styleUrl: './price-cell-invitation-entity.component.scss'
})
export class PriceCellInvitationEntityComponent {
  CurrencyChangerType = CurrencyChangerType;

  @Input({required: true}) invitation: (IReservationInvitation & { entityType: ReservationTableEntityType.INVITATION }) | undefined;

}
