





<div class="compensate-parent-padding py-3 {{ textColorClass }}" [style.background]="'var('+bgColor+')'">

  <div class="flex justify-content-between align-items-start w-full">
    <div class="flex flex-column flex-1">

      <div>
        <h5 class="m-0">

          <ng-content select="[slot=title]">

          </ng-content>

        </h5>

        <p class="m-0">
          <ng-content select="[slot=subtitle]">

          </ng-content>
        </p>
      </div>

      <div class="flex align-items-center column-gap-3 flex-wrap">

        <ng-content select="[slot=detail]">

        </ng-content>

      </div>
    </div>

    <!-- <div class="flex align-items-end text-xl font-bold">
      <ng-content select="[slot=price]">

      </ng-content>
    </div> -->
  </div>
</div>
