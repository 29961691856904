

<div class="session-attendance-statusbar-wrap">
  <ng-container *ngFor="let item of sessionAttendances">
    <div
      [class.session-attendance-statusbar--active]="item.session.id == currentSessionId"
      class="session-attendance-statusbar {{ saHelper.getSessionAttendanceStatus({
        state: $any(item).state ?? null,
        excused: $any(item).excused ?? null,
        replaced: $any(item).replaced ?? null,
        noAttendanceYet: $any(item).noAttendanceYet
      }).bgClasses
    }}">
    </div>
  </ng-container>
</div>