import { Pipe, PipeTransform } from '@angular/core';
import { ITranslationObject } from '../models/shop-item/shop-item-custom-settings.model';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../enums/utils/languages.enum';

@Pipe({
  name: 'translateCustomLabel',
  standalone: true
})
export class TranslateCustomLabelPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(value: ITranslationObject): string {
    const currentLang = (this.translate.currentLang || Language.CS) as Language;
    return value[currentLang];
  }

}
