

export enum OfferState {
  NEW = 'NEW',
  IN_PREPARATION = 'IN_PREPARATION',
  ISSUED = 'ISSUED',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  FINISHED = 'FINISHED',
};

