import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceSize, SystemService } from '../services/system.service';

@Directive({
  selector: '[appWindowSize]',
  standalone: true
})
export class WindowSizeDirective implements OnDestroy {

  @HostBinding('class') windowSizeClass: string | undefined;

  sub: Subscription = Subscription.EMPTY;

  constructor(
    sysService: SystemService
  ) {
    this.sub = sysService.deviceSize$.subscribe(size => {
      this.handleSize(size);
    });
  }

  private handleSize(size: DeviceSize) {
    this.windowSizeClass = `${size}_size`;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
