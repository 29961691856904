import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { SystemService } from './system.service';

export enum ErrorSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
  UNKNOWN = 'UNKNOWN',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED'
}

export enum ErrorKnown {
  FS_QUERY_REQUIRES_AN_INDEX = 'FS_QUERY_REQUIRES_AN_INDEX',
  FAILED_TO_FETCH_DYNAMICALLY_IMPORTED_MODULE = 'FAILED_TO_FETCH_DYNAMICALLY_IMPORTED_MODULE',
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  knownErrors: ErrorKnown[] = [];
  unknownErrors: {
    err: any,
    message: string | null
  }[] = [];

  constructor(
    private utilsService: UtilsService,
    private sysService: SystemService
  ) { }

  async onUnknownErrorOccured(err: any) {
    const message = err.message || null;
    if (!message) return;

    const found = this.unknownErrors.find(e => e.err.message === err.message);

    this.unknownErrors.push({ err, message });
    if (!found) {
      await this.utilsService.logError(`Error message: ${message}\n\nstack:\n${err.stack}`, ErrorSeverity.UNKNOWN);
    }
    
  }

  async onKnownErrorOccured(err: ErrorKnown, errMessage: string) {
    this.knownErrors.push(err);

    if (err === ErrorKnown.FS_QUERY_REQUIRES_AN_INDEX) {
      await this.utilsService.logError(errMessage, ErrorSeverity.CRITICAL);
    } else if (err === ErrorKnown.FAILED_TO_FETCH_DYNAMICALLY_IMPORTED_MODULE) {
      this.sysService.newAppVersion$.next(true);
      if (errMessage.includes("'text/html' is not a valid JavaScript MIME type.")) {
        // hopefully temporary .. dávám to sem kvuli tomu, že je podezreni, že tento error taky nastává při nové verzi
        // domnívám se ale, že tenhle error může způsobovat i nějaký jiný problém jinde, takže proto tu nechávám logování a
        // pokud se toto bude opakovaně logovat (protože user bude opakovane promptovan k refreshi), tak to bude znamenat, že
        // tohle je problém a musi se to resit nejak jinak ... každopadne je v to že to nastává po vydání nové verze je jiste.. jde o
        // to jestli to nastava i jindy... to se zjisti ... pridano 26.11.2024
        await this.utilsService.logError(errMessage, ErrorSeverity.INFO);
      }
    }
  }

}
