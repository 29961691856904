import { Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { SessionFormat } from 'src/app/shared/enums/session-group/session-formats.enum';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-shop-item-quick-info',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
  
    NgbTooltipModule,
  ],
  templateUrl: './shop-item-quick-info.component.html',
  styleUrls: ['./shop-item-quick-info.component.scss'],
  providers: [ LocalizedDatePipe ]
})
export class ShopItemQuickInfoComponent {

  _shopItem: IShopItem | undefined;
  get shopItem() { return this._shopItem; };
  @Input({required: true}) set shopItem(val: IShopItem | undefined) { this._shopItem = val; };
  @Input({required: true}) locationUseInternalTitle: boolean = false;

  info: string[] = [];

  constructor(
    private ldatePipe: LocalizedDatePipe,
    public siHelper: ShopItemHelperService,
  ) {}

}
