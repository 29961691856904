

<app-modal-header
  (close)="close()">
</app-modal-header>

<div class="modal-body text-center align-items-center flex flex-column">
<ng-container *ngIf="(preparingLink$ | async); else prepared">
  <h5 class="m-0 mb-3">{{'admin.download-modal.collectingData' | translate }}</h5>

  <p-progressSpinner></p-progressSpinner>
</ng-container>
<ng-template #prepared>
  <h5 class="m-0 mb-3">{{'admin.download-modal.dataReady' | translate }}</h5>
  
  <i class="pi pi-check text-green-500" style="font-size: 7.5rem;"></i>
  
  <button (click)="onDownload()" pButton icon="pi pi-download" label="{{'admin.download-modal.download' | translate }}" class="p-button-success"></button>

</ng-template>

</div>