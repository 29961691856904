import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, finalize, Subscription, take, tap } from 'rxjs';
import { IOrganization } from '../../models/organization/organization.model';
import { IOrganizationInfoUpdateData, IOrganizationsCustomDomainsUpdateData, IOrganizationsCustomDomainsVerifyData, OrganizationsService } from '../entities/organizations/organizations.service';
import { SelectedOrgService } from '../selected-org.service';
import { UtilsService } from '../utils.service';
import { StoreService } from './store.service';
import { IOrgCustomDomainsVerifiedFE } from '../../models/organization/custom-domain.model';
import { getOrgCustomDomainsVerifiedForFE } from '../helpers/organization-helper.service';

export interface IOrganizationState {
  org$: BehaviorSubject<IOrganization & { customDomainsVerified: IOrgCustomDomainsVerifiedFE } | null>,
  fetchingOrg$: BehaviorSubject<boolean>,
  updatingOrg$: BehaviorSubject<boolean>,
};

@Injectable({
  providedIn: 'root'
})
export class OrganizationStoreService implements OnDestroy {

  private initState: IOrganizationState = {
    org$: new BehaviorSubject<IOrganization & { customDomainsVerified: IOrgCustomDomainsVerifiedFE } | null>(null),
    fetchingOrg$: new BehaviorSubject<boolean>(false),
    updatingOrg$: new BehaviorSubject<boolean>(false),
  };
  public state = this.utilsService.initializeState(this.initState) as IOrganizationState;

  subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService,
    private selectedOrgService: SelectedOrgService,
    private organizationsService: OrganizationsService,
    private store: StoreService,
  ) {
    
    this.subs.push(
      this.selectedOrgService.selectedOrgId$.subscribe(orgId => {
        this.init(orgId);
      }),

      // actions
      store.actions.organization_updateOrganizationInState$.subscribe(() => {
        const orgId = this.state.org$.getValue()?.id;
        if (orgId) this.updateOrgInState(orgId);
      })
    );
  }

  private init(orgId: number | null) {
    if (!orgId) {
      this.utilsService.resetState(this.initState, this.state);
      return;
    }

    this.state.fetchingOrg$.next(true);
    this.getFreshOrg(orgId).pipe(
      take(1),
      finalize(() => this.state.fetchingOrg$.next(false))
    ).subscribe({
      next: (res) => {
        this.state.org$.next(this.calcOrg(res));
      }
    });
  }

  public updateInfo(data: IOrganizationInfoUpdateData) {
    this.state.updatingOrg$.next(true);
    return this.organizationsService.updateInfo(data).pipe(
      take(1),
      finalize(() => this.state.updatingOrg$.next(false)),
      tap(x => {
        this.updateOrgInState(x);
      })
    );
  }

  public updateCustomDomains(data: IOrganizationsCustomDomainsUpdateData) {
    this.state.updatingOrg$.next(true);
    return this.organizationsService.updateCustomDomains(data).pipe(
      take(1),
      finalize(() => this.state.updatingOrg$.next(false)),
      tap(x => {
        this.updateOrgInState(data.orgId);
      })
    );
  }

  public verifyCustomDomain(data: IOrganizationsCustomDomainsVerifyData) {
    this.state.updatingOrg$.next(true);
    return this.organizationsService.verifyCustomDomain(data).pipe(
      take(1),
      finalize(() => this.state.updatingOrg$.next(false)),
      tap(x => {
        this.updateOrgInState(data.orgId);
      })
    );
  }

  private updateOrgInState(orgId: number) {
    this.state.updatingOrg$.next(true);
    this.getFreshOrg(orgId).pipe(
      take(1),
      finalize(() => this.state.updatingOrg$.next(false))
    ).subscribe({
      next: (res) => {
        this.state.org$.next(this.calcOrg(res));
        this.store.actions.organization_organizationUpdated$.next(res);
      }
    });
  }
  private getFreshOrg(orgId: number) {
    // get event
    return this.organizationsService.getOrgById(orgId);
  }

  private calcOrg(org: IOrganization) {
    return { ...org, customDomainsVerified: getOrgCustomDomainsVerifiedForFE(org) };
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
