import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { Language } from 'src/app/shared/enums/utils/languages.enum';
import { IOrganization } from 'src/app/shared/models/organization/organization.model';
import { AutomaticCommunicationScope } from '../../store/shop-item-admin-store.service';

export enum MessageTemplateName {
  EVENT_CREATED_USER_ORG_REGISTERED = 'EVENT_CREATED_USER_ORG_REGISTERED',
  NEW_ROLES_ADDED = 'NEW_ROLES_ADDED',
  ORG_MEMBER_INVITATION_TO_LEKTORY = 'ORG_MEMBER_INVITATION_TO_LEKTORY',
  REGISTERED_GENERATED_PASSWORD = 'REGISTERED_GENERATED_PASSWORD',
  RESERVATION_APPROVED = 'RESERVATION_APPROVED',
  RESERVATION_APPROVED_FROM_WL = 'RESERVATION_APPROVED_FROM_WL',
  RESERVATION_IN_WAITINGLIST = 'RESERVATION_IN_WAITINGLIST',
  RESERVATION_SUBMITTED = 'RESERVATION_SUBMITTED',
  DEMAND_SUBMITTED = 'DEMAND_SUBMITTED',
  NEW_DEMAND_RECEIVED = 'NEW_DEMAND_RECEIVED',
  COMPLETE_ORDER_REMINDER = 'COMPLETE_ORDER_REMINDER',
  ORDER_NOT_PAID_REMINDER = 'ORDER_NOT_PAID_REMINDER',
  ORDER_CREATED = 'ORDER_CREATED',
  PARTNER_REGISTRATION_NOT_PAID_REMINDER = 'PARTNER_REGISTRATION_NOT_PAID_REMINDER',
  PARTNER_REGISTRATION_NOT_ORDERED_REMINDER = 'PARTNER_REGISTRATION_NOT_ORDERED_REMINDER',
  RESERVATION_CANCELED_BY_SYSTEM = 'RESERVATION_CANCELED_BY_SYSTEM',
  RESERVATION_CANCELED_BY_ADMIN = 'RESERVATION_CANCELED_BY_ADMIN',
  RESERVATION_CANCELED_BY_CUSTOMER = 'RESERVATION_CANCELED_BY_CUSTOMER',
  RESERVATION_CANCELED_BY_PARTNER = 'RESERVATION_CANCELED_BY_PARTNER',
  COUPLE_RESERVATION_CANCELED_BY_SYSTEM = 'COUPLE_RESERVATION_CANCELED_BY_SYSTEM',
  ORDER_CANCELED_BY_SYSTEM = 'ORDER_CANCELED_BY_SYSTEM',
  ORDER_CANCELED_BY_ADMIN = 'ORDER_CANCELED_BY_ADMIN',
  ORDER_CANCELED_BY_CUSTOMER = 'ORDER_CANCELED_BY_CUSTOMER',
  PAYMENT_RECEIVED_UNPAID = 'PAYMENT_RECEIVED_UNPAID',
  NEW_RESERVATION_SUBMITED = 'NEW_RESERVATION_SUBMITED',
  RESERVATION_CONFIRMED = 'RESERVATION_CONFIRMED',
  RESERVATION_CHANGED_SHOPITEM = 'RESERVATION_CHANGED_SHOPITEM',
  WORK_SUMMARY = 'WORK_SUMMARY',
  EMAIL_BTN_STYLE = 'EMAIL_BTN_STYLE',
  SIGNATURE_COORDINATOR = 'SIGNATURE_COORDINATOR',
  SIGNATURE_BASIC = 'SIGNATURE_BASIC',
  SIGNATURE = 'SIGNATURE',
  SIGNATURE_SYSTEM = 'SIGNATURE_SYSTEM',
  C2A_BTN_STYLE = 'C2A_BTN_STYLE',
  ERROR = 'ERROR',
  INTERNAL_NEW_ORG = 'INTERNAL_NEW_ORG',
  EMAIL_BTN_STYLE_SECONDARY = 'EMAIL_BTN_STYLE_SECONDARY',
}

// TODO move both enum and interface to /models

export interface IMessageTemplateTranslationObj {
  [language: string]: string;
}

export interface IMessageTemplate {
  id: number;
  name: MessageTemplateName;
  partial: boolean;
  html: string;
  text: string;
  subjects: IMessageTemplateTranslationObj;
  htmls: IMessageTemplateTranslationObj;
  texts: IMessageTemplateTranslationObj;
  subject: string;
  language: Language;

  organizationId: number | null;
  organization?: IOrganization;

  // messageNotifications?: IMessageNotification[] // TODO: add when needed
}

interface ICallablesMessageTemplatesGetData {
  organizationId?: number;
  shopItemId?: number;
  reservationId?: number;
  name: MessageTemplateName;
};

export interface ICallablesMessageTemplatesUpsertData {
  notifications: {
    messageNotificationId?: number;
    label: string;
    subjects: IMessageTemplateTranslationObj;
    htmls: IMessageTemplateTranslationObj;
    texts: IMessageTemplateTranslationObj;
    templateName: MessageTemplateName;
  }[];
  
  scope: AutomaticCommunicationScope;
  scopeIds: {
    shopItemId: number | null;
    eventId: number | null;
    organizationId: number | null;
  };

  toOrganizationId: number;
}

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {

  constructor(
    private dbService: DbService,
  ) { }

  public upsert(data: ICallablesMessageTemplatesUpsertData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesMessageTemplatesUpsert, data });
    return this.dbService.handleObs(obs);
  }

  public getOrganizationSpecific(data: ICallablesMessageTemplatesGetData): Observable<IMessageTemplate[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesMessageTemplatesGet, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
