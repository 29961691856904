import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { Currency } from '../enums/price/currencies.enum';
import { IPrice } from '../models/price/price.model';
import { CurrencyService } from '../services/currency.service';

@Pipe({
  name: 'price',
  standalone: true,
})
export class PricePipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
    private currencyService: CurrencyService
  ) {}

  transform(value: IPrice[], currency?: Currency, negative?: boolean, minFractionDigits = 0): Observable<string> {
    const fallbackPrice = value[0];

    if (!fallbackPrice) return of('');

    // currency from parameter
    if (currency) {
      const p = value.find(x => x.currency === currency);
      if (p) return of( this.createPriceString(p, negative, minFractionDigits) );
      else return of( this.createPriceString(fallbackPrice, negative, minFractionDigits) );
    }

    // currency from service
    return this.currencyService.getCurrency$().pipe(
      map(c => {
        const price = value.find(x => x.currency === c);
        return this.createPriceString(price ? price : fallbackPrice, negative, minFractionDigits);
      })
    );
  }

  public createPriceString(price: IPrice, negative?: boolean, minFractionDigits = 0) {
    let priceValue = price.value;
    if (priceValue !== null) {
      priceValue = negative ? priceValue * -1 : priceValue;
    }
    return `${this.currencyPipe.transform(
      priceValue,
      price.currency,
      'symbol',
      `1.${minFractionDigits}-2`,
      'cs-CZ'
    )?.toString()}`;
  }

}
