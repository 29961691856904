

export enum TransferType {
  CREDIT = 'CREDIT',
  INCOME = 'INCOME',
  REFUND = 'REFUND',
  STORNO = 'STORNO',
  STORNO_FEE = 'STORNO_FEE',
};

