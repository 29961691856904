import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DataType } from 'src/app/shared/enums/collecting-data/data-type.enum';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { BoolPipe } from 'src/app/shared/pipes/bool.pipe';
import { TableModule } from 'primeng/table';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { JoinPipe } from 'src/app/shared/pipes/join.pipe';
import { InflectionPipe } from 'src/app/shared/pipes/inflection.pipe';

type CustomParams = {
  dataType: DataType.CONSENT;
  validUntil: Date | string | null;
  expirationDays: number | null;
  valid: boolean;
} | {
  dataType: DataType.BIRTHDATE;
  ageInYears: number;
};

@Component({
  selector: 'td[app-custom-data-cell]',
  standalone: true,
  imports: [
    CommonModule, TranslateModule, TableModule, NgbTooltipModule,
    LocalizedDatePipe, BoolPipe, JoinPipe, InflectionPipe
  ],
  templateUrl: './custom-data-cell.component.html',
  styleUrls: ['./custom-data-cell.component.scss']
})
export class CustomDataCellComponent {
  DataType = DataType;

  @Input({ required: true }) value: any;
  @Input({ required: true }) collectedAt!: Date | null;
  @Input({ required: true }) customParams!: CustomParams | null;
  @Input({ required: true }) dataType!: DataType | null;

  hoverTimeoutId: any;
  tooltipOpened = false;

  tableTooltipEnter(t: NgbTooltip) {
    if (!this.tooltipOpened) {
      t.open();
      this.tooltipOpened = true;
    }
    if (this.hoverTimeoutId) {
      clearTimeout(this.hoverTimeoutId);
    }
  }

  tableTooltipLeave(t: NgbTooltip) {
    this.hoverTimeoutId = setTimeout(() => {
      t.close();
      this.tooltipOpened = false;
    }, 300);
  }

}
