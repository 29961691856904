import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { PaymentGatePaymentStatusComponent } from '../payment-gate-payment-status/payment-gate-status.component';
import { PaymentGatePaymentStatus } from '../payment-gate-redirect.module';

@Component({
  selector: 'app-stripe-redirect',
  standalone: true,
  imports: [
    CommonModule,
    PaymentGatePaymentStatusComponent,
  ],
  templateUrl: './stripe-redirect.component.html',
  styleUrls: ['./stripe-redirect.component.scss']
})
export class StripeRedirectComponent implements OnInit {

  paymentStatus?: PaymentGatePaymentStatus;
  transferId?: string;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getParams();
  }


  private getParams() {
    this.paymentStatus = <PaymentGatePaymentStatus | null>this.route.snapshot.paramMap.get('paymentStatus') ?? undefined;
    this.route.queryParams.pipe(take(1)).subscribe({
      next: (res) => {
        this.transferId = res['id']; // stripe payment id
      }
    });
  }
}
