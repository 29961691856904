

<app-modal-header
  (close)="close()">
  <span slot="title">{{ 'admin.reservations.change-shopitem.modal.title' | translate }}</span>
</app-modal-header>

<div class="modal-body">

  <ng-container *ngIf="(fetchingCandidateShopItems || fetchingMessageTemplate); else notFetching">
    <div class="flex justify-content-center align-items-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </ng-container>

  <ng-template #notFetching>

    <div *ngIf="candidateData" class="grid">
      <div class="field col-12">
        {{ 'admin.reservations.change-shopitem.modal.text' | translate }}
        <p-listbox
          [options]="candidateData.candidates"
          [filter]="candidateData.candidates.length > 7 ? true : false"
          [filterFields]="['shopItem.title']"
          [ngModel]="selectedCandidate"
          (ngModelChange)="onCandidateChange($event)"
          emptyMessage="{{ 'admin.reservations.change-shopitem.modal.empty-message.label' | translate }}"
          [listStyle]="{'max-height':'230px'}">
          <ng-template let-d pTemplate="item">
            <div class="flex justify-content-between align-items-center w-full">
              <app-shop-item-quick-info [shopItem]="d.shopItem" [locationUseInternalTitle]="true"></app-shop-item-quick-info>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </div>


    <div [formGroup]="form" *ngIf="selectedCandidate" class="grid">
      <!-- show matched and unmatched customData -->
      
      <!-- TODO pozdeji - ukazat co se mappne a co ne -->
      <!-- Matched:
      <app-custom-data-inputs [customDataFG]="mappedCustomDataFG" [userData]="selectedCandidate.mappedCustomData"></app-custom-data-inputs>

      Unmatched:
      <app-custom-data-inputs [customDataFG]="unmappableCustomDataFG" [userData]="selectedCandidate.unmappableCustomData"></app-custom-data-inputs> -->

      <div class="field col-12">
        <span class="p-float-label">
          <p-dropdown
            [options]="possibleReservationStates"
            optionValue="value"
            optionLabel="label"
            formControlName="reservationState">
            <ng-template let-item pTemplate="selectedItem">
              <div>{{ item.label | translate }}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div>{{ item.label | translate }}</div>
            </ng-template>
          </p-dropdown>
          <label>{{ 'admin.reservations.change-shopitem.modal.target-reservation-state.label' | translate }}</label>
        </span>
      </div>

      <div *ngIf="form.controls.sessionGroupCoupleRole" class="col-12 mb-2">
        {{ 'admin.reservations.change-shopitem.modal.couple-role.label' | translate }}
        <p-selectButton [options]="sessionGroupCoupleRoleOptions" formControlName="sessionGroupCoupleRole"></p-selectButton>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <p-inputNumber
            appNumberInput
            [formControl]="form.controls.daysToExpire"
            suffix=" {{ daysString(form.controls.daysToExpire.value) | translate }}"
          >
        </p-inputNumber>
        <label>{{ 'admin.reservations.change-shopitem.modal.days-to-expire.label' | translate }}</label>
        </span>
      </div>

      <div class="field col-12">
        <p-checkbox formControlName="checkWL" [binary]="true" label="{{ 'admin.reservations.change-shopitem.modal.check-wl.label' | translate }}"></p-checkbox>
      </div>

      <div class="field col-12">
        <p-checkbox formControlName="sendNotification" [binary]="true" label="{{ 'admin.reservations.change-shopitem.modal.send-notification.label' | translate }}"></p-checkbox>
      </div>

      <div *ngIf="form.controls.notification" class="col-12" formGroupName="notification">
        <div class="field p-inputgroup">
          <span class="p-inputgroup-addon">{{ 'admin.event.form-section.reservation-confirmation.from-input.label' | translate }}</span>
          <input type="text" pInputText formControlName="replyTo">
        </div>

        <div class="field p-inputgroup">
          <span class="p-inputgroup-addon">{{ 'admin.event.form-section.reservation-confirmation.subject-input.label' | translate }}</span>
          <input type="text" pInputText formControlName="subject">
        </div>

        <app-tinymce-editor
          [FC]="form.controls.notification.controls.template"
          [editorSettings]="editorSettings">
        </app-tinymce-editor>
      </div>

      <div class="field col-12">
        <span class="p-float-label w-full">
          <input pInputText formControlName="adminNote" type="text" class="w-full">
          <label>{{ 'admin.reservations.change-shopitem.modal.admin-note.label' | translate }}</label>
        </span>
      </div>

    </div>

  </ng-template>
</div>

<div class="modal-footer flex flex-row justify-content-between">

  <button
    (click)="close()"
    pButton
    label="{{ 'admin.reservations.change-shopitem.modal.cancel-btn.label' | translate }}"
    class="p-button-sm p-button-text p-button-plain">
  </button>

  <button
    *ngIf="selectedCandidate"
    [loading]="sending"
    (click)="onSubmit()"
    pButton
    label="{{ 'admin.reservations.change-shopitem.modal.confirm-btn.label' | translate }}"
    class="p-button-sm">
  </button>


</div>