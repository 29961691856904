import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { DiscountType } from 'src/app/shared/enums/discount/discount-types.enum';
import { DiscountValidIn } from 'src/app/shared/enums/discount/discount-valid-in.enum';
import { DiscountApplicableFor } from 'src/app/shared/enums/discount/discount-applicable-for.enum';
import { Observable } from 'rxjs';
import { IDiscountTemplate } from 'src/app/shared/models/discount/discount-template.model';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';

export interface IDiscountsTemplatesCreateData {
  title: string;
  description: string;
  percentValue: number;
  type: DiscountType;
  validIn: DiscountValidIn;
  organizationId: number;
  applicableFor: DiscountApplicableFor;
  canBeUsedCnt: number | null;
  specificShopItemIds: number[];
  code: string;
  expiration: Date | null;
  eventId: number | null;
};

export interface IDiscountsTemplatesUpdateData {
  id: number;
  title: string;
  description: string;
  percentValue: number;
  type: DiscountType;
  validIn: DiscountValidIn;
  applicableFor: DiscountApplicableFor;
  canBeUsedCnt: number | null;
  specificShopItemIds: number[];
  code: string;
  expiration: Date | null;
  eventId: number | null;
}

export interface IDiscountsApplicableCreateData {
  discountTemplateId: number;
  visibilityForCustomer: boolean;
  shopItemId: number;
};

export interface IDiscountsApplicableRemoveData {
  applicableDiscountIds: number[];
}

interface IDiscountsTemplatesGetForShopItemSelectData {
  shopItemId: number;  
}
interface IDiscountsTemplatesGetForShopItemSelectResult {
  shopItem: IShopItem;
  orgDiscountTemplates: IDiscountTemplate[];
  eventDiscountTemplates: {
    event: { id: number; title: string };
    discountTemplates: IDiscountTemplate[];
  };
};

interface IDiscountsTemplatesGetEventDiscountsData {
  eventUuid: string;  
}
type IDiscountsTemplatesGetEventDiscountsResult = (IDiscountTemplate & { _count: { applicableDiscounts: number } })[];


@Injectable({
  providedIn: 'root'
})
export class DiscountsService {

  constructor(
    private dbService: DbService,
  ) { }

  public getEventDiscounts(data: IDiscountsTemplatesGetEventDiscountsData): Observable<IDiscountsTemplatesGetEventDiscountsResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsTemplatesGetEventDiscounts, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getDiscountTemplatesForShopItemSelect(data: IDiscountsTemplatesGetForShopItemSelectData): Observable<IDiscountsTemplatesGetForShopItemSelectResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsTemplatesGetForShopItemSelect, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public createDiscountTemplate(data: IDiscountsTemplatesCreateData): Observable<IDiscountTemplate> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsTemplatesCreate, data });
    return this.dbService.handleObs(obs);
  }

  public updateDiscountTemplate(data: IDiscountsTemplatesUpdateData): Observable<IDiscountTemplate> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsTemplatesUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createApplicableDiscount(data: IDiscountsApplicableCreateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsApplicableCreate, data });
    return this.dbService.handleObs(obs);
  }

  public removeApplicableDiscounts(data: IDiscountsApplicableRemoveData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsApplicableRemove, data });
    return this.dbService.handleObs(obs);
  }

  public archiveDiscountTemplate(discountTemplateId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsTemplatesArchive, data: { discountTemplateId } });
    return this.dbService.handleObs(obs);
  }

  public applicableIn(discountTemplateId: number): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsApplicableIn, data: { discountTemplateId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public removeAppliedDiscount(reservationId: number, discountTemplateId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsAppliedRemove, data: { reservationId, discountTemplateId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getAppliedCount(discountTemplateId: number): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsAppliedCount, data: { discountTemplateId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
