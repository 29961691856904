

export enum SessionGroupRegistrationType {
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_SINGLE = 'INDIVIDUAL_SINGLE',
  COUPLE = 'COUPLE',
  INDIVIDUALS_COUPLE = 'INDIVIDUALS_COUPLE',
  INDIVIDUAL_COLLEAGUES = 'INDIVIDUAL_COLLEAGUES', // individuální rezervace (hlásím sebe) + hlásím kolegy
  PARENT_KIDS = 'PARENT_KIDS', // rodic hlasi deti
  PARENT_KIDS_INDIVIDUAL = 'PARENT_KIDS_INDIVIDUAL', // rodic hlasi deti + individuální rezervace
  ADULT_CHILDREN_GROUP = 'ADULT_CHILDREN_GROUP', // dospely hlasi skupinu deti
  ADULT_WITH_CHILDREN_GROUP = 'ADULT_WITH_CHILDREN_GROUP',// dospely a skupina deti
  ADULT_GROUP = 'ADULT_GROUP',
  NO_RESERVATIONS = 'NO_RESERVATIONS',
  ON_DEMAND = 'ON_DEMAND'
}
