import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { CustomerNoteState, ICustomerNote } from 'src/app/shared/models/customer-note/customer-note.model';

export interface ICustomerNotesGetPaginationData {
  customerId: number;
  orgId: number;

  page: number;
  pageSize: number;
}

export interface ICustomerNotesCreateData {
  customerId: number;
  orgId: number;

  title: string | null;
  content: string;
}

export interface ICustomerNotesUpdateData {
  noteUuid: string;

  title: string | null;
  orgId: number;
  content: string;
}

export interface ICustomerNotesUpdateStateData {
  noteUuid: string;

  state: CustomerNoteState;
}

interface ICustomerNotesDeleteData {
  noteUuid: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerNotesService {


  constructor(
    private dbService: DbService,
  ) { }

  public deleteCustomerNote(data: ICustomerNotesDeleteData): Observable<string> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomerNotesDelete, data });
    return this.dbService.handleObs(obs);
  }

  public updateCustomerNote(data: ICustomerNotesUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomerNotesUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public updateCustomerNoteState(data: ICustomerNotesUpdateStateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomerNotesUpdateState, data });
    return this.dbService.handleObs(obs);
  }

  public create(data: ICustomerNotesCreateData): Observable<ICustomerNote> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomerNotesCreate, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getCustomerNotesPagination(data: ICustomerNotesGetPaginationData): Observable<{customerNotes: ICustomerNote[], totalCount: number }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCustomerNotesGetPagination, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

}
