import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IUserSessionGroupCurrentlyAttendingAttendance } from 'src/app/shared/services/entities/users/customer/customers.service';
import { UserShopItemSessionAttendanceComponent } from 'src/app/shared/components/session-attendance/user-shop-item-session-attendance/user-shop-item-session-attendance.component';

@Component({
  selector: 'app-attending-item',
  standalone: true,
  imports: [
    CommonModule,
  
    UserShopItemSessionAttendanceComponent,
  ],
  templateUrl: './attending-item.component.html',
  styleUrls: ['./attending-item.component.scss']
})
export class AttendingItemComponent {

  @Input() item: IUserSessionGroupCurrentlyAttendingAttendance | undefined;

}
