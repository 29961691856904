import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { Subscription, take } from 'rxjs';
import { SiItemNoteEditorComponent } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-notes-modal/si-item-note-editor/si-item-note-editor.component';
import { ImageSize } from 'src/app/shared/enums/image/image-sizes.enum';
import { ConfirmDeleteModalComponent } from 'src/app/shared/modals/confirm-delete-modal/confirm-delete-modal.component';
import { CustomerNoteState, ICustomerNote } from 'src/app/shared/models/customer-note/customer-note.model';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { OrgUserInitialsPipe } from 'src/app/shared/pipes/org-user-initials.pipe';
import { OrgUserNameOrEmailPipe } from 'src/app/shared/pipes/org-user-name-or-email.pipe';
import { CustomerNotesService } from 'src/app/shared/services/entities/customer-notes/customer-notes.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-customer-note',
  standalone: true,
  imports: [
    TranslateModule,
    AvatarModule, MenuModule, ButtonModule,
    OrgUserInitialsPipe, LocalizedDatePipe, OrgUserNameOrEmailPipe, SiItemNoteEditorComponent,
    NgbTooltipModule,
  ],
  templateUrl: './customer-note.component.html',
  styleUrl: './customer-note.component.scss'
})
export class CustomerNoteComponent {
  ImageSize = ImageSize;
  CustomerNoteState = CustomerNoteState;

  _note: ICustomerNote | undefined;
  get note() { return this._note; };
  @Input({required: true}) set note(val: ICustomerNote | undefined) { this._note = val; this.FC.setValue(val?.content ?? ''); };

  @Output() deleted = new EventEmitter<void>();

  FC = new FormControl<string>('', { nonNullable: true });

  noteMenuItems: MenuItem[] = [];

  deleting: boolean = false;
  updating: boolean = false;
  updatingState: boolean = false;
  editing: boolean = false;

  @HostBinding('class.loading-box') get loading() { return this.deleting || this.updating; };

  subs: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private customerNotesService: CustomerNotesService,
    private modalService: ModalService,
    private selectedOrgService: SelectedOrgService,
    private utilsService: UtilsService
  ) {
    this.subs.push(
      this.translate.onLangChange.subscribe(() => {
        this.createMenuItems();
      })
    );
  }

  ngOnInit(): void {
    this.createMenuItems();
  }

  private createMenuItems() {
    this.noteMenuItems = [
      {
        label: this.translate.instant('admin.customer-note.menu.edit-label'),
        icon: 'pi pi-pencil',
        command: () => this.onWantEdit()
      },
      {
        label: this.translate.instant('admin.customer-note.menu.remove-label'),
        icon: 'pi pi-trash',
        command: () => this.onWantDelete(),
      }
    ]
  }

  onUpdate() {
    if (!this.note || this.updating) return;
    this.updating = true;
    const orgId = this.selectedOrgService.selectedOrgId$.getValue();
    if (!orgId) {
      this.utilsService.logError('Missing orgId! qwfjhqfhqwfjqwhjqfw');
      return;
    }
    this.customerNotesService.updateCustomerNote({
      noteUuid: this.note.uuid,
      title: this.note.title,
      content: this.FC.value,
      orgId
    }).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.note = { ...this.note!, content: this.FC.value };
        this.updating = false;
        this.editing = false;
      },
      error: (err) => {
        this.updating = false;
      }
    })
  }

  private onWantEdit() {
    this.editing = !this.editing;
  }

  private onWantDelete() {

    const res = this.modalService.openConfirmDeleteModal({
      c: ConfirmDeleteModalComponent,
      text: 'admin.customer-note.menu.remove.confirm.text',
      title: 'admin.customer-note.menu.remove.confirm.title',
    });

    res.pipe(
      take(1)
    ).subscribe(r => {
      if (r) {
        if (!this.note || this.deleting) return;
        this.deleting = true;
        this.customerNotesService.deleteCustomerNote({
          noteUuid: this.note.uuid
        }).pipe(
          take(1)
        ).subscribe({
          next: () => {
            this.deleting = false;
            this.deleted.emit();
          },
          error: (err) => {
            this.deleting = false;
          }
        })
      }
    })
  }

  onStateToggle() {
    if (!this.note || this.updatingState) return;

    this.updatingState = true;
    const nextState = this.note.state === CustomerNoteState.SOLVED ? CustomerNoteState.UNSOLVED : CustomerNoteState.SOLVED;
    this.customerNotesService.updateCustomerNoteState({
      noteUuid: this.note.uuid,
      state: nextState
    }).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.note = { ...this.note!, state: nextState };
        this.updatingState = false;
      },
      error: (err) => {
        this.updatingState = false;
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
