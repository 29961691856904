import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OverlayListenerOptions, OverlayOptions, SelectItem } from 'primeng/api';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { Subscription } from 'rxjs';
import { SelectionLimit } from 'src/app/shared/modals/form-field-settings-modal/form-field-settings-modal.component';
import { GetCustomDataResultType } from 'src/app/shared/services/entities/users/customer/customers.service';

@Component({
  selector: 'app-multiselect-custom-input',
  standalone: true,
  imports: [
    DropdownModule, MultiSelectModule, ReactiveFormsModule, FormsModule
  ],
  templateUrl: './multiselect-custom-input.component.html',
  styleUrl: './multiselect-custom-input.component.scss'
})
export class MultiselectCustomInputComponent implements OnInit, OnDestroy {
  SelectionLimit = SelectionLimit;

  @Input({ required: true }) userData!: GetCustomDataResultType;
  @Input({ required: true }) FC!: FormControl<string[]>;

  dropdownNgModel: string = '';

  options: SelectItem[] = [];

  subs: Subscription[] = [];

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.userData.params.selectionLimit === SelectionLimit.ONE_ITEM) {
      if (this.FC.value && Array.isArray(this.FC.value)) {
        this.dropdownNgModel = this.FC.value.at(0) || '';
      }
    }

    this.createOptions();
    this.subs.push(this.translate.onLangChange.subscribe(() => this.createOptions()));
  }

  onDropdownChange(event: DropdownChangeEvent) {
    this.FC.setValue([event.value]);
    this.FC.markAsDirty();
  }

  private createOptions() {
    this.options = [...new Set([...(this.userData.params.options || []), ...(this.userData.value || [])])].map((x) => {
      return {
        value: x,
        label: this.translate.instant(x)
      };
    });
  }

  getOverlayOptions(): OverlayOptions {
    // diky tomuto se overlay nezavre pri scrollu mimo overlay - pomaha to v pripade, kdy se overlay otevre castecne pod nejakym elementem
    // user chce tudiz odscrollovat aby videl cely overlay a tak zustane otevreny - bez tohoto by se pri outside scrollu zavrel
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        if (options?.type === 'scroll') {
          return false;
        }
        return options?.valid;
      }
    };
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
