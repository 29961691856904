import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { SelectItem } from 'primeng/api';

export enum InvoiceType {
  INVOICE = 'INVOICE',
  PRO_FORMA = 'PRO_FORMA'
}

@Component({
  selector: 'app-si-invoice-type-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule, DropdownModule
  ],
  templateUrl: './si-invoice-type-input.component.html',
  styleUrls: ['./si-invoice-type-input.component.scss']
})
export class SiInvoiceTypeInputComponent {
  @Input() invoiceTypeFC?: FormControl<InvoiceType>;

  invoiceTypeOptions: SelectItem[] = Object.values(InvoiceType).map((value) => ({
    value,
    label: `InvoiceType.${value}`
  }));

}
