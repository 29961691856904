import { Directive, ElementRef, HostListener } from '@angular/core';
import { InputNumber } from 'primeng/inputnumber';

@Directive({
  selector: '[appNumberInput]',
  standalone: true
})
export class NumberInputDirective {

  constructor(private el: InputNumber) { 
    
  }

  @HostListener('onInput') onInput() {
    const inputEl = <HTMLInputElement>this.el.input.nativeElement;
    setTimeout(() => {
      const formattedValue = this.el.formattedValue() as string;
      if (inputEl.selectionStart === formattedValue.length) {
        const trimmedFormattedValue = formattedValue.replace(this.el.suffix ?? '', '');
        const position = trimmedFormattedValue.length || 0;
        inputEl.selectionEnd = inputEl.selectionStart = position;
      }
    });
  }
  
  @HostListener('onFocus') onFocus() {
    setTimeout(()=> {
      const inputEl = <HTMLInputElement>this.el.input.nativeElement;
      // inputEl.selectionEnd = inputEl.selectionStart = 0;
      inputEl.select()
    });
  }
  
}
