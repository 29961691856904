import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISingleSessionAttendanceFE, SessionAttendanceState } from 'src/app/shared/models/session-attendance/session-attendance.model';
import { SessionAttendanceHelperService } from 'src/app/shared/services/helpers/session-attendance-helper.service';

@Component({
  selector: 'app-session-attendance-status-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './session-attendance-status-bar.component.html',
  styleUrls: ['./session-attendance-status-bar.component.scss']
})
export class SessionAttendanceStatusBarComponent {
  SessionAttendanceState = SessionAttendanceState;

  @Input() sessionAttendances: ISingleSessionAttendanceFE[] | undefined;
  @Input() currentSessionId: number | undefined;

  constructor(
    public saHelper: SessionAttendanceHelperService
  ) {}

}
