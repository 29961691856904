import { Pipe, PipeTransform } from '@angular/core';
import { IOrgUser } from '../models/user/user.model';
import { UserHelperService } from '../services/helpers/user-helper.service';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'orgUserNameOrEmail',
  standalone: true
})
export class OrgUserNameOrEmailPipe implements PipeTransform {

  constructor(
    private uHelper: UserHelperService,
    private utilsService: UtilsService
  ) {}

  transform(user: Partial<IOrgUser> | { name?: string | null; surname?: string | null; } | null | undefined): string {
    if (!user) {
      console.error('User not found!');
      this.utilsService.logError(`User not found! qefqfqfqf`);
      return '';
    }
    return this.uHelper.getFullName({ name: user.name ?? null, surname: user.surname ?? null });
  }

}
