import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { PaymentGatePaymentStatusComponent } from '../payment-gate-payment-status/payment-gate-status.component';
import { PaymentGatePaymentStatus } from '../payment-gate-redirect.module';

@Component({
  selector: 'app-comgate-redirect',
  standalone: true,
  imports: [
    CommonModule,
    PaymentGatePaymentStatusComponent,
  ],
  templateUrl: './comgate-redirect.component.html',
  styleUrls: ['./comgate-redirect.component.scss']
})
export class ComgateRedirectComponent implements OnInit {

  paymentStatus: PaymentGatePaymentStatus | undefined;

  comgateTransferId: string | undefined;
  comgateOurId: string | null = null;
  waitUntilSuccess: boolean = false;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getParams();
  }


  private getParams() {
    this.paymentStatus = <PaymentGatePaymentStatus | null>this.route.snapshot.paramMap.get('paymentStatus') ?? undefined;
    this.route.queryParams.pipe(take(1)).subscribe({
      next: (res) => {
        this.comgateTransferId = res['id']; // comgate payment id
        this.comgateOurId = res['refId']; // our id in comgate (transfer)
        this.waitUntilSuccess = res['wait'] === 'true';
      }
    });
  }
}
