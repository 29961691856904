<div class="flex">
  <div class="col-3 p-0 flex" *ngIf="invoiceIssueDaysFC">
    <p-inputNumber
      appNumberInput
      inputId="invoiceIssueDays"
      [formControl]="invoiceIssueDaysFC"
      [ngModel]="invoiceIssueDaysFC.value"
      class="p-fluid w-full"
      [min]="1"
      placeholder="{{ (invoiceIssueEventFC?.value === OrderEvent.ON_PAYMENT || invoiceIssueEventFC?.value === ShopItemEvent.ON_ORDER) ? ('admin.shop-item.form-section.price-invoicing.invoice-issue-days.placeholder' | translate) : '' }}"
      suffix=" {{ daysString(invoiceIssueDaysFC.value) | translate }}"
    />
  </div>
  <div class="col-9 p-0 flex" *ngIf="invoiceIssueEventFC">
    <p-dropdown
      [formControl]="invoiceIssueEventFC"
      [ngModel]="invoiceIssueEventFC.value"
      class="w-full"
      styleClass="w-full"
      inputStyleClass="w-full"
      [options]="invoiceIssueEventOptions"
      optionLabel="label"
      optionValue="value"
      [ngbTooltip]="invoiceIssueEventFC.disabled ? ('admin.shop-item.form-section.price-invoicing.maturity-start.disabled-tooltip' | translate) : null"
    >
      <ng-template let-item pTemplate="selectedItem">
        <div>{{ item.label | translate }}</div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div>{{ item.label | translate }}</div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
