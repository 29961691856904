

<div #scrollElement class="flex flex-column flex-1 overflow-auto" (scroll)="onScroll($event, scrollElement)">

  <div class="h-full flex flex-column" [class.loading-box]="fetching && page === 0">

    <div class="notes-wrap" >

      @for (note of notes; track note) {
        <app-customer-note [note]="note" (deleted)="onItemDetelted(note.uuid)"></app-customer-note>
      }

      <div class="w-full h-3rem" [class.loading-box]="fetching">

      </div>

    </div>

  </div>

</div>

<ng-template #footerEl>
  <div class="w-full  mt-3">

    <app-si-item-note-editor [FC]="form.controls.content"></app-si-item-note-editor>
  
    <div class="mt-2">
      <button
        (click)="onCreateNote()"
        pButton
        [loading]="sending"
        [disabled]="sending || form.invalid"
        [label]="'admin.customer-card-notes.create-btn.label' | translate"
        class="p-button-sm w-full">
      </button>
    </div>
  </div>
</ng-template>