import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { daysString } from 'src/app/shared/components/date-input/date-input.component';
import { Subscription } from 'rxjs';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

export enum ShopItemEvent {
  ON_ORDER = 'ON_ORDER',
  BEFORE_START = 'BEFORE_START',
  AFTER_START = 'AFTER_START'
}

export enum OrderEvent {
  ON_PAYMENT = 'ON_PAYMENT',
  BEFORE_START = 'BEFORE_START',
  AFTER_START = 'AFTER_START'
}

@Component({
  selector: 'app-si-maturity-start-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule, DropdownModule, InputNumberModule, FormsModule, NumberInputDirective,
    NgbTooltipModule
  ],
  templateUrl: './si-maturity-start-input.component.html',
  styleUrls: ['./si-maturity-start-input.component.scss']
})
export class SiMaturityStartInputComponent implements OnInit, OnDestroy {
  daysString = daysString;
  OrderEvent = OrderEvent;
  ShopItemEvent = ShopItemEvent;

  @Input() type: 'OrderEvent' | 'ShopItemEvent' = 'ShopItemEvent';
  @Input() invoiceIssueEventFC?: FormControl<OrderEvent | ShopItemEvent>;
  @Input() invoiceIssueDaysFC?: FormControl<number | null>;

  invoiceIssueEventOptions: SelectItem[] = [];

  subs: Subscription[] = [];

  ngOnInit(): void {
    this.invoiceIssueEventOptions = Object.values(this.type === 'OrderEvent' ? OrderEvent : ShopItemEvent).map((e) => ({
      value: e,
      label: `${this.type}.${e}`
    }));

    this.subs.push(
      this.invoiceIssueEventFC?.valueChanges.subscribe((invoiceIssueEvent) => {
        if (invoiceIssueEvent === OrderEvent.ON_PAYMENT || invoiceIssueEvent === ShopItemEvent.ON_ORDER) {
          this.invoiceIssueDaysFC?.setValue(null, { emitEvent: false });
          this.invoiceIssueDaysFC?.disable({ emitEvent: false });
        } else if (!this.invoiceIssueDaysFC?.value) {
          this.invoiceIssueDaysFC?.enable({ emitEvent: false });
          setTimeout(() => this.invoiceIssueDaysFC?.setValue(1, { emitEvent: false }), 1);
        } else if (this.invoiceIssueDaysFC.disabled) {
          this.invoiceIssueDaysFC?.enable({ emitEvent: false });
        }
      }) || Subscription.EMPTY
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
