import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { Modal } from '../modal';
import { UtilsService } from '../../services/utils.service';
import { CountryDropdownInputComponent } from '../../components/country-dropdown-input/country-dropdown-input.component';
import { TranslateModule } from '@ngx-translate/core';

export interface IContactAddress {
  name: string;
  address: IAddress;
  useBillingAddress?: boolean;
}

export interface IAddress {
  buildingNumber: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  countryCode: string;
};

export interface IAddressFG {
  buildingNumber: FormControl<string>;
  street: FormControl<string>;
  city: FormControl<string>;
  zip: FormControl<string>;
  country: FormControl<string>;
  countryCode: FormControl<string>;
};

export interface ICompanyInfoFG {
  ico: FormControl<string>;
  vatNumber: FormControl<string>;
}

export const getAddressFG = ({ address, required = false }: { address?: IAddress, required?: boolean }) => {
  const commonValidators = required ? [ Validators.required ] : [];
  return new FormGroup<IAddressFG>({
    buildingNumber: new FormControl(address?.buildingNumber || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    street: new FormControl(address?.street || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    city: new FormControl(address?.city || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    zip: new FormControl(address?.zip || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    country: new FormControl(address?.country || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    countryCode: new FormControl(address?.countryCode || '', { nonNullable: true, validators: [ ...commonValidators ] }),
  });
};

export const getCompanyInfoFG = ({ico, vatNumber, required}: { ico?: string; vatNumber?: string; required?: boolean; }) => {
  return new FormGroup<ICompanyInfoFG>({
    vatNumber: new FormControl(vatNumber || '', { nonNullable: true }),
    ico: new FormControl(ico || '', { nonNullable: true, validators: required ? [Validators.required] : undefined })
  });
};

@Component({
  selector: 'app-address-form-group-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    InputTextModule, ButtonModule,
    ModalHeaderComponent, CountryDropdownInputComponent, TranslateModule
  ],
  templateUrl: './address-form-group-modal.component.html',
  styleUrls: ['./address-form-group-modal.component.scss']
})
export class AddressFormGroupModalComponent extends Modal implements OnInit {

  @Input() FG: FormGroup<IAddressFG> | undefined;
  @Input() nameFC?: FormControl<string>;
  @Input() companyInfoFG?: FormGroup<ICompanyInfoFG>;

  @Output() submitted = new EventEmitter<void>;

  internalFG = getAddressFG({});
  internalNameFC: FormControl<string> = new FormControl('', { nonNullable: true });
  internalCompanyInfoFG: FormGroup<ICompanyInfoFG> = getCompanyInfoFG({});

  constructor(
    private utilsSerivce: UtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.FG) {
      this.internalFG.setValue(this.FG.getRawValue());
    }
    if (this.nameFC) {
      this.internalNameFC.setValue(this.nameFC.getRawValue());
    }
    if (this.companyInfoFG) {
      this.internalCompanyInfoFG.setValue(this.companyInfoFG.getRawValue());
      if (this.companyInfoFG.controls.ico.hasValidator(Validators.required)) {
        this.internalCompanyInfoFG.controls.ico.addValidators(Validators.required);
        this.internalCompanyInfoFG.controls.ico.updateValueAndValidity();
      }
    }
  }

  onSubmit() {
    this.utilsSerivce.markFormGroupDirty(this.internalFG);
    this.utilsSerivce.markFormGroupDirty(this.internalCompanyInfoFG);
    this.utilsSerivce.markControlDirty(this.internalNameFC);
    if (this.internalFG.invalid || this.internalCompanyInfoFG.invalid || this.internalNameFC.invalid) {
      console.error('invalid');
      return;
    };

    this.FG?.setValue(this.internalFG.getRawValue());
    this.nameFC?.setValue(this.internalNameFC.getRawValue());
    this.companyInfoFG?.setValue(this.internalCompanyInfoFG.getRawValue());
    this.submitted.next();
    this.close();
  }

}
