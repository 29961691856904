import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IUserSessionGroupExcusedForReplacementAttendance } from 'src/app/shared/services/entities/users/customer/customers.service';
import { ButtonModule } from 'primeng/button';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { LocationNamePipe } from 'src/app/shared/pipes/location-name.pipe';
import { StoreService } from 'src/app/shared/services/store/store.service';
import { SessionAttendancesService } from 'src/app/shared/services/entities/session-attendances/session-attendances.service';
import { finalize, first } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { SessionFormat } from 'src/app/shared/enums/session-group/session-formats.enum';

@Component({
  selector: 'app-excused-for-replacement-item',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule,
    LocalizedDatePipe, LocationNamePipe,
  ],
  templateUrl: './excused-for-replacement-item.component.html',
  styleUrls: ['./excused-for-replacement-item.component.scss']
})
export class ExcusedForReplacementItemComponent implements OnInit {
  SessionFormat = SessionFormat;

  @Input() item: IUserSessionGroupExcusedForReplacementAttendance | undefined;
  @Input() userId: number | undefined;

  replacing: boolean = false;

  constructor(
    private store: StoreService,
    private sessionAttendancesService: SessionAttendancesService
  ) {}

  ngOnInit(): void {
  }

  onReplace(item: IUserSessionGroupExcusedForReplacementAttendance) {
    if (!this.userId) {
      console.error('No user id');
      return;
    }
    if (this.replacing) return;

    this.replacing = true;
    this.sessionAttendancesService.replace({
      sessionId: item.sessionAttendance.session.id,
      userId: this.userId,
    }).pipe(first(), finalize(() => this.replacing = false)).subscribe({
      next: () => {
        this.store.actions.sessionAttendance_sessionAttendanceChanged$.next();
      },
    })
  }
}
