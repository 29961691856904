
<ng-template #tooltipContent>
  <p class="m-0">
    @if (copied) {
      {{ 'tooltip-copy-content.copied' | translate }}
    } @else {
      {{ 'tooltip-copy-content.copy' | translate }}
    }
  </p>
  @if (!copied) {
    <p class="m-0">{{ value }}</p>
  }
</ng-template>