

export enum TransferProvider {
  COMGATE = 'COMGATE',
  STRIPE = 'STRIPE',
  INTERNAL = 'INTERNAL',
  FIO = 'FIO',
  GOPAY = 'GOPAY',
  FAKTUROID = 'FAKTUROID'
};

export const TransferProviderName = new Map<TransferProvider, string>([
  [TransferProvider.COMGATE, 'Comgate'],
  [TransferProvider.STRIPE, 'Stripe'],
  [TransferProvider.INTERNAL, 'Interní'],
  [TransferProvider.FIO, 'FIO'],
  [TransferProvider.GOPAY, 'GoPay'],
  [TransferProvider.FAKTUROID, 'Fakturoid']
]);
