import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bool',
  standalone: true
})
export class BoolPipe implements PipeTransform {

  constructor() {}

  transform(data: boolean): string {
    return `bool.${data}`;
  }

}
