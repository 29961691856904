


<app-modal-header
  (close)="close()">
  <span slot="title">
    {{ 'admin.order-expiration-dates-modal.title' | translate }}
  </span>
</app-modal-header>

<div class="modal-body grid">

  <div class="field col-12 md:col-4">
    <div class="p-float-label">
      <p-calendar
        [(ngModel)]="maturityDate"
        [showTime]="true"
        [minDate]="minMaturityValue"
        [dateFormat]="'primeng.dateFormat' | translate"
        inputId="maturity">
      </p-calendar>
      <label for="maturity">{{ 'admin.order-expiration-dates-modal.maturity-input.label' | translate }}</label>
    </div>
  </div>

  <div class="field col-12 md:col-4">
    <div class="p-float-label">
      <p-calendar
        [(ngModel)]="expirationDate"
        [showTime]="true"
        [minDate]="minExpirationValue"
        [dateFormat]="'primeng.dateFormat' | translate"
        inputId="expiration">
      </p-calendar>
      <label for="expiration">{{ 'admin.order-expiration-dates-modal.expiration-input.label' | translate }}</label>
    </div>
  </div>

</div>

<div class="modal-footer">

  <button
    pButton
    (click)="close()"
    label="{{ 'admin.order-expiration-dates-modal.cancel-btn.label' | translate }}"
    class="p-button-sm p-button-text p-button-plain">
  </button>

  <button
    pButton
    (click)="onSubmit()"
    label="{{ 'admin.order-expiration-dates-modal.confirm-btn.label' | translate }}"
    class="p-button-sm">
  </button>

</div>