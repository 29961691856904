

<div #scrollElement class="flex flex-column flex-1 overflow-auto">

  <div class="h-full flex flex-column">

    <div class="notes-wrap" >

      @for (message of data; track data; let i = $index) {
        <app-message-item [recipientUserId]="customerId" [message]="message"></app-message-item>

        @if (data[i + 1]?.sentAt && data[i]?.sentAt) {
          @if (isMoreThanWeekApart(data[i + 1].sentAt!, data[i].sentAt!)) {
            <div class="text-bluegray-200">
              <div class="w-full text-center">
                <i class="pi pi-ellipsis-v text-lg"></i>
              </div>
              <div class="w-full text-center mt-2 mb-4">
                <i class="pi pi-ellipsis-v text-lg"></i>
              </div>
            </div>
          }
        }
      }

    </div>

    @if (fetching) {
      <div class="w-full h-4rem loading-box"></div>
    }
  </div>


  @if (!noMoreData && !fetching) {
    <button
      (click)="loadMore()"
      [label]="'admin.admin-customer-card-modal.tab.messages.load-more-label' | translate"
      pButton
      class="text-sm">
    </button>
  }

</div>
