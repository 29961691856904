import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, finalize, of, take, tap } from 'rxjs';
import { ICustomerCard } from 'src/app/pages/admin/org-admin/components/modals/customer-card-modal/admin-customer-card-modal.component';
import { CustomersService, ICallablesUserDataUpdate, ICustomerCardGetByUserData, ICustomerCardIncludeData } from '../entities/users/customer/customers.service';
import { UtilsService } from '../utils.service';
import { IDiscountsAppliedChangeCallableData, IOrderItemPriceUpdateData, OrderItemsService } from '../entities/order-items/order-items.service';
import { IOrderSetDatesData, IOrderSetExpirationData, OrdersService } from '../entities/orders/orders.service';
import { StoreService } from './store.service';
import { DiscountsService } from '../entities/discounts/discounts.service';

export enum CustomerCardInvoker {
  ADMIN = 'ADMIN',
  LECTURER = 'LECTURER'
}

export interface ICustomerCardState {
  customerCard$: BehaviorSubject<ICustomerCard | null>;

  fetchingUser$: BehaviorSubject<boolean>;
  fetchingOrders$: BehaviorSubject<boolean>;
  fetchingReservations$: BehaviorSubject<boolean>;
  fetchingCredit$: BehaviorSubject<boolean>;
  fetchingUserData$: BehaviorSubject<boolean>;
  fetchingAttendance$: BehaviorSubject<boolean>;

  updatingUser$: BehaviorSubject<number[]>;
  updatingOrders$: BehaviorSubject<number[]>;
  updatingReservations$: BehaviorSubject<number[]>;
  updatingUserData$: BehaviorSubject<boolean>;

}

@Injectable({
  providedIn: 'root'
})
export class CustomerCardStoreService implements OnDestroy {

  
  private initState: ICustomerCardState = {
    customerCard$: new BehaviorSubject<ICustomerCard | null>(null),

    fetchingUser$: new BehaviorSubject<boolean>(false),
    fetchingOrders$: new BehaviorSubject<boolean>(false),
    fetchingReservations$: new BehaviorSubject<boolean>(false),
    fetchingCredit$: new BehaviorSubject<boolean>(false),
    fetchingUserData$: new BehaviorSubject<boolean>(false),
    fetchingAttendance$: new BehaviorSubject<boolean>(false),

    updatingUser$: new BehaviorSubject<number[]>([]),
    updatingOrders$: new BehaviorSubject<number[]>([]),
    updatingReservations$: new BehaviorSubject<number[]>([]),
    updatingUserData$: new BehaviorSubject<boolean>(false),
  };
  public state = this.utilsService.initializeState(this.initState) as ICustomerCardState;

  invoker: CustomerCardInvoker | undefined;
  initData: ICustomerCardGetByUserData | undefined;

  subs: Subscription[] = [];

  constructor(
    private customersService: CustomersService,
    private utilsService: UtilsService,
    private orderItemsService: OrderItemsService,
    private discountsService: DiscountsService,
    private ordersService: OrdersService,
    private store: StoreService
  ) {
    this.subs.push(
      this.store.actions.sessionAttendance_sessionAttendanceChanged$.subscribe(() => {
        this.updateAttendanceDataInState();
      })
    )
  }

  public init(customerId: number, orgId: number, invoker: CustomerCardInvoker) {
    this.invoker = invoker;
    this.utilsService.resetState(this.initState, this.state);
    this.initData = undefined;


    const data: ICustomerCardGetByUserData = {
      userId: customerId,
      orgId: orgId,
      include: this.getInvokerIncludeData({
        orders: true,
        reservations: true,
        user: true,
        credit: true,
        userData: true,
        attendance: true
      })
    };
    this.initData = data;

    

    this.fetchData(data).pipe(
      take(1),
      
    ).subscribe(res => {
      const customerCard: ICustomerCard = {
        user: res.user,
        orders: res.orders,
        reservations: res.reservations,
        credit: res.credit,
        userData: res.userData,
        attendance: res.attendance
      }
      this.state.customerCard$.next( customerCard );
    });
  }

  public updateOrderDates(data: IOrderSetDatesData) {
    this.addUpdatingOrdersToState([data.orderId]);
    return this.ordersService.setDates([data]).pipe(
      take(1),
      tap(() => {
        this.updateOrdersInState([data.orderId]);
      })
    );
  }

  public updateOrderItemPrice(data: IOrderItemPriceUpdateData) {
    const orderId = this.state.customerCard$.getValue()?.orders?.find(x => x.orderItems.find(y => y.id === data.orderItemId))?.orderId;
    if (!orderId) return of(null);
    this.addUpdatingOrdersToState([orderId]);
    return this.orderItemsService.updatePrice(data).pipe(
      take(1),
      tap(() => {
        this.updateOrdersInState([orderId]);
      })
    );
  }

  public updateOrderItemDiscount(data: IDiscountsAppliedChangeCallableData, orderId: number) {
    this.addUpdatingOrdersToState([orderId]);
    return this.orderItemsService.updateDiscountValue(data).pipe(
      take(1),
      tap(() => {
        this.updateOrdersInState([orderId]);
      })
    );
  }

  public removeOrderItemDiscount(reservationId: number, discountTemplateId: number, orderId: number) {
    this.addUpdatingOrdersToState([orderId]);
    return this.discountsService.removeAppliedDiscount(reservationId, discountTemplateId).pipe(
      take(1),
      tap(() => {
        this.updateOrdersInState([orderId]);
      })
    );
  }

  public updateCustomData(data: ICallablesUserDataUpdate) {
    this.state.updatingUserData$.next(true);
    return this.customersService.updateCustomerData(data).pipe(
      take(1),
      finalize(() => this.state.updatingUserData$.next(false)),
      tap(() => {
        this.updateUserDataInState();
      })
    );
  }

  private updateAttendanceDataInState() {
    if (!this.initData) return;

    const data: ICustomerCardGetByUserData = {
      ...this.initData,
      include: this.getInvokerIncludeData({
        attendance: true
      })
    };

    this.fetchData(data).pipe(
      take(1),
    ).subscribe({
      next: (res) => {
        let card = this.state.customerCard$.getValue();
        if (!card) return;
        card.attendance = res.attendance;
        this.state.customerCard$.next(card);
      }
    })
  }

  private updateUserDataInState() {
    if (!this.initData) return;
    const data: ICustomerCardGetByUserData = {
      ...this.initData,
      include: this.getInvokerIncludeData({
        userData: true,
        user: true
      })
    };

    this.fetchData(data).pipe(
      take(1),
    ).subscribe({
      next: (res) => {
        let card = this.state.customerCard$.getValue();
        if (!card) return;
        card.userData = res.userData;
        card.user = res.user;
        this.state.customerCard$.next(card);
      }
    })
  }

  private updateOrdersInState(orderIds: number[]) {
    if (!this.initData) return;
    this.addUpdatingOrdersToState(orderIds);
    
    const data: ICustomerCardGetByUserData = {
      ...this.initData,
      include: this.getInvokerIncludeData({
        orders: true,
      })
    };

    this.fetchData(data).pipe(
      take(1),
      finalize(() => this.removeUpdatingOrdersFromState(orderIds))
    ).subscribe({
      next: (res) => {
        let card = this.state.customerCard$.getValue();
        if (!card) return;
        const updatedOrders = res.orders?.filter(x => orderIds.includes(x.orderId)) ?? [];
        const currOrders = card.orders ?? [];

        // update directly changed reservations in state
        for (let updatedOrder of updatedOrders) {
          const index = currOrders.findIndex(x => x.orderId === updatedOrder.orderId); 
          if (index >= 0) {
            currOrders[index] = updatedOrder;
          }
        }
        
        card.orders = currOrders;

        const c = this.state.customerCard$.getValue()
        this.state.customerCard$.next({ ...c!, orders: currOrders});
        
      }
    })
  }


  private addUpdatingOrdersToState(ids: number[]) {
    this.state.updatingOrders$.next( [ ...this.state.updatingOrders$.getValue(), ...ids ] );
  }
  private removeUpdatingOrdersFromState(ids: number[]) {
    this.state.updatingOrders$.next(this.state.updatingOrders$.getValue().filter(x => !ids.includes(x)));
  }

  private getInvokerIncludeData(incl: ICustomerCardIncludeData): ICustomerCardIncludeData {
    switch (this.invoker) {
      case CustomerCardInvoker.ADMIN:
        // allow all for admin
        return {
          orders: incl.orders ? true : false,
          reservations: incl.reservations ? true : false,
          user: incl.user ? true : false,
          credit: incl.credit ? true : false,
          userData: incl.userData ? true : false,
          attendance: incl.attendance ? true : false
        };
      case CustomerCardInvoker.LECTURER:
        // allow only few for lecturer
        return {
          orders: false,
          reservations: false,
          user: incl.user ? true : false,
          credit: false,
          userData: incl.userData ? true : false,
          attendance: incl.attendance ? true : false
        };
      default:
        console.error(`Invalid invoker: ${this.invoker}`);
        return {};
    }
  }


  private fetchData(data: ICustomerCardGetByUserData) {
    if (data.include.user) this.state.fetchingUser$.next(true);
    if (data.include.orders) this.state.fetchingOrders$.next(true);
    if (data.include.reservations) this.state.fetchingReservations$.next(true);
    if (data.include.credit) this.state.fetchingCredit$.next(true);
    if (data.include.userData) this.state.fetchingUserData$.next(true);
    if (data.include.attendance) this.state.fetchingAttendance$.next(true); 

    return this.customersService.getCustomerCard(data).pipe(
      take(1),
      finalize(() => {
        if (data.include.user) this.state.fetchingUser$.next(false);
        if (data.include.orders) this.state.fetchingOrders$.next(false);
        if (data.include.reservations) this.state.fetchingReservations$.next(false);
        if (data.include.credit) this.state.fetchingCredit$.next(false);
        if (data.include.userData) this.state.fetchingUserData$.next(false);
        if (data.include.attendance) this.state.fetchingAttendance$.next(false); 
      })
    );
  }


  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
