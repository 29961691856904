

@if (reservation; as r) {
  @if (r.entityType === ReservationTableEntityType.RESERVATION) {
    @if (r.shopItem?.sessionGroup?.registrationType; as t) {
      @switch (t) {
        @case (SessionGroupRegistrationType.INDIVIDUALS_COUPLE) {
          @if (r.partner) {
            <ng-container *ngTemplateOutlet="withPartner, context: {
              partner: r.partner,
              partnerReservation: r.partnerReservation
            }"></ng-container>
          } @else {
            <ng-container *ngTemplateOutlet="typeLabel, context: {
              type: 'SessionGroupRegistrationType.'+SessionGroupRegistrationType.INDIVIDUAL | translate
            }"></ng-container>
    
            <ng-container *ngTemplateOutlet="roleLabel, context: {
              role: r.sessionGroupCoupleRole
            }"></ng-container>
          }
        }
        @case (SessionGroupRegistrationType.COUPLE) {
          <ng-container *ngTemplateOutlet="withPartner, context: {
            partner: r.partner,
            partnerReservation: r.partnerReservation
          }"></ng-container>
        }
        @default {
          <ng-container *ngTemplateOutlet="typeLabel, context: {
            type: 'SessionGroupRegistrationType.'+t | translate
          }"></ng-container>
        }
      }

      
      <ng-template #withPartner let-partner="partner" let-partnerReservation="partnerReservation">
        @if (partner && partnerReservation) {
          <div class="row">
            <ng-container *ngTemplateOutlet="typeLabel, context: {
              type: 'SessionGroupRegistrationType.'+SessionGroupRegistrationType.COUPLE | translate
            }"></ng-container>
            <ng-container *ngTemplateOutlet="roleLabel, context: {
              role: r.sessionGroupCoupleRole
            }"></ng-container>
            <span class="text-xs">s</span>
          </div>
          <div [ngbTooltip]="partner.email" class="row">
            <span class="white-space-nowrap">
              {{ partner | userNameOrEmail:partnerReservation.organizationId }} 
              <ng-container *ngTemplateOutlet="roleLabel, context: {
                role: partnerReservation.sessionGroupCoupleRole,
              }"></ng-container>
            </span>
          </div>
        }
      </ng-template>
    }

  }
  @else if (r.entityType === ReservationTableEntityType.INVITATION) {
    <ng-container *ngTemplateOutlet="typeLabel, context: {
      type: 'ReservationTableEntityType.' + ReservationTableEntityType.INVITATION | translate
    }"></ng-container>
  }
}


<ng-template #typeLabel let-type="type">
  <span class="font-bold">
    {{ type | translate }}
  </span>
</ng-template>


<ng-template #roleLabel let-role="role">
  @if (reservation?.shopItem?.sessionGroup; as sg) {
    <span class="text-xs">
      @if (role === SessionGroupCoupleRole.A) {
        ({{ sg.roleLabelA?.[0] | uppercase }})
      }
      @if (role === SessionGroupCoupleRole.B) {
        ({{ sg.roleLabelB?.[0] | uppercase }})
      }
    </span>
  }
</ng-template>