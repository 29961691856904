import { Component, Input, OnInit } from '@angular/core';
import { MessageItemComponent } from './message-item/message-item.component';
import { IMessagesGetForCustomerCardResult, MessagesService } from 'src/app/shared/services/entities/messages/messages.service';
import { take } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';

@Component({
  selector: 'app-customer-card-messages',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule,
    MessageItemComponent,
  ],
  templateUrl: './customer-card-messages.component.html',
  styleUrl: './customer-card-messages.component.scss'
})
export class CustomerCardMessagesComponent implements OnInit {

  @Input({required: true}) customerId: number | undefined;

  data: IMessagesGetForCustomerCardResult[] = [];

  fetching: boolean = false;
  page: number = 0;
  pageSize: number = 10;
  noMoreData: boolean = false;

  constructor(
    private messagesService: MessagesService,
    private selectedOrg: SelectedOrgService,
  ) { }

  ngOnInit() {
    this.fetchMessages();
  }

  loadMore() {
    this.page++;
    this.fetchMessages();
  }

  fetchMessages() {
    const orgId = this.selectedOrg.getCurrentValue();
    if (!this.customerId || !orgId) return;
    this.fetching = true;
    this.messagesService.getForUserCard({
      orgId: orgId,
      userId: this.customerId,
      page: this.page,
      pageSize: this.pageSize
    }).pipe(
      take(1)
    ).subscribe(
      data => {
        this.data.push(...data);
        this.fetching = false;
        if (data.length < this.pageSize) {
          this.noMoreData = true;
        }
      },
      () => {
        this.fetching = false;
      }
    );
  }

  // function to determine if two dates are 7 or more days apart
  isMoreThanWeekApart(date1: string, date2: string): boolean {
    const diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
    return diff >= 7 * 24 * 60 * 60 * 1000;
  }
}
