import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { IAddress } from '../modals/address-form-group-modal/address-form-group-modal.component';

@Pipe({
  name: 'address',
  standalone: true,
})
export class AddressPipe implements PipeTransform {

  constructor(
    public utilsService: UtilsService
  ) {}

  transform(address?: IAddress | string | null, separator: string = ', '): string {
    if (!address) return '';
    let items: string[] = [];
    if (typeof address === 'string') {
      items = address.split(', ');
    } else {
      items = [`${address.street || ''} ${address.buildingNumber || ''}`, `${address.zip || ''}`, `${address.city || ''}`, `${address.country || ''}`];
    }
    return items.filter((item) => item.trim() !== '').join(separator);
  }
}
