

<app-modal-header
  (close)="close()">
  <span slot="title">{{ 'admin.org-fill-missing-data-modal.title' | translate }}</span>
</app-modal-header>

<div [formGroup]="form" class="modal-body grid">

  <div *ngIf="legalNameFC" class="field col-12">
    <span class="p-float-label">
      <input type="text" pInputText formControlName="legalName" class="w-full">
      <label for="legalName">{{ 'admin.org-fill-missing-data-modal.companyname' | translate }}</label>
    </span>
  </div>

</div>

<div class="modal-footer">

  <button
    [loading]="saving"
    [disabled]="saving"
    (click)="onSubmit()"
    pButton
    label="{{ 'admin.org-fill-missing-data-modal.save' | translate }}">
  </button>

</div>
