import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../models/user/user.model';
import { UserHelperService } from '../services/helpers/user-helper.service';

@Pipe({
  name: 'join',
  standalone: true
})
export class JoinPipe implements PipeTransform {

  constructor() {}

  transform(data: string[], separator: string): string {
    return data.filter((x) => x).join(separator);
  }

}
