<div>
  <textarea
    #adminNoteTextArea
    (blur)="onBlur()"
    rows="1"
    class="w-full border-0 hover:surface-hover focus:surface-hover"
    [autoResize]="true"
    pInputTextarea
    [(ngModel)]="adminNoteInputValue">
  </textarea>
</div>
