import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomTableColumnItemGroupType, ICustomTableColumnItem, ReservationTableColumnItemGroupType } from '../custom-table-columns-modal.component';
import { I18nService } from 'src/app/shared/services/i18n.service';

export enum ReservationTableColumnItemType {
  BENEFICIARY_USER_NAME = 'BENEFICIARY_USER_NAME',
  BENEFICIARY_USER_EMAIL = 'BENEFICIARY_USER_EMAIL',
  BENEFICIARY_USER_CERT_HOLDER = 'BENEFICIARY_USER_CERT_HOLDER',
  BENEFICIARY_USER_CERTS = 'BENEFICIARY_USER_CERTS',
  BENEFICIARY_USER_ROLES = 'BENEFICIARY_USER_ROLES',
  BENEFICIARY_USER_COLLECTED_DATA = 'BENEFICIARY_USER_COLLECTED_DATA', // TODO

  RESERVER_USER_NAME = 'RESERVER_USER_NAME',
  RESERVER_USER_EMAIL = 'RESERVER_USER_EMAIL',
  RESERVER_USER_COLLECTED_DATA = 'RESERVER_USER_COLLECTED_DATA', // TODO

  SHOP_ITEM_TITLE = 'SHOP_ITEM_TITLE',
  SHOP_ITEM_CODE = 'SHOP_ITEM_CODE',
  SHOP_ITEM_ID = 'SHOP_ITEM_ID',
  SHOP_ITEM_RESERVATION_TYPE = 'SHOP_ITEM_RESERVATION_TYPE',
  SHOP_ITEM_EVENT_TITLE = 'SHOP_ITEM_EVENT_TITLE',
  SHOP_ITEM_EVENT_CODE = 'SHOP_ITEM_EVENT_CODE',
  SHOP_ITEM_START_AT = 'SHOP_ITEM_START_AT',
  SHOP_ITEM_END_AT = 'SHOP_ITEM_END_AT',
  SHOP_ITEM_CATEGORIES = 'SHOP_ITEM_CATEGORIES',
  SHOP_ITEM_RESERVATION_STATE = 'SHOP_ITEM_RESERVATION_STATE',
  SHOP_ITEM_INTERNAL_TAGS = 'SHOP_ITEM_INTERNAL_TAGS',
  SHOP_ITEM_CUSTOM_ITEM_NUMBER = 'SHOP_ITEM_CUSTOM_ITEM_NUMBER',

  RESERVATION_STATE = 'RESERVATION_STATE',
  RESERVATION_CREATED_AT = 'RESERVATION_CREATED_AT',
  RESERVATION_CANCELLED_AT = 'RESERVATION_CANCELLED_AT',
  RESERVATION_APPROVED_AT = 'RESERVATION_APPROVED_AT',
  RESERVATION_CONFIRMED_AT = 'RESERVATION_CONFIRMED_AT',
  RESERVATION_GUEST = 'RESERVATION_GUEST',
  RESERVATION_QUANTITY = 'RESERVATION_QUANTITY',
  RESERVATION_COLLECTED_DATA = 'RESERVATION_COLLECTED_DATA', // TODO
  RESERVATION_ADMIN_NOTE = 'RESERVATION_ADMIN_NOTE',
  RESERVATION_APPLIED_DISCOUNTS = 'RESERVATION_APPLIED_DISCOUNTS', // TODO
  RESERVATION_FINAL_PRICE = 'RESERVATION_FINAL_PRICE',

  ORDER_NUMBER = 'ORDER_NUMBER',
  ORDER_CREATED_AT = 'ORDER_CREATED_AT',
  ORDER_COMPLETED_AT = 'ORDER_COMPLETED_AT',
  ORDER_CANCELLED_AT = 'ORDER_CANCELLED_AT',
  ORDER_MATURITY = 'ORDER_MATURITY',
  ORDER_INVOICE_NUMBER = 'ORDER_INVOICE_NUMBER',
  ORDER_PROFORMA_NUMBER = 'ORDER_PROFORMA_NUMBER',
  ORDER_BILLING_INFO = 'ORDER_BILLING_INFO',
  ORDER_INVOICING_CONTACT = 'ORDER_INVOICING_CONTACT', // TODO
  ORDER_FINAL_PRICE = 'ORDER_FINAL_PRICE',
  ORDER_CUSTOMER_NOTE = 'ORDER_CUSTOMER_NOTE',
  ORDER_CURRENCY = 'ORDER_CURRENCY',

  // TODO: ???
  ORDER_ITEM_PRICE = 'ORDER_ITEM_PRICE',
  ORDER_ITEM_VAT = 'ORDER_ITEM_VAT',

  COMPANY_NUMBER = 'COMPANY_NUMBER',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  COMPANY_EMAIL = 'COMPANY_EMAIL', // TODO
  COMPANY_PHONE = 'COMPANY_PHONE', // TODO
  COMPANY_WEBSITE = 'COMPANY_WEBSITE', // TODO
  COMPANY_CUSTOM_DATA = 'COMPANY_CUSTOM_DATA' // TODO
}

export enum ReservationTableExportItemType {
  BENEFICIARY_USER_NAME = 'BENEFICIARY_USER_NAME',
  BENEFICIARY_USER_SURNAME = 'BENEFICIARY_USER_SURNAME',
  BENEFICIARY_USER_EMAIL = 'BENEFICIARY_USER_EMAIL',
  BENEFICIARY_USER_CERT_HOLDER = 'BENEFICIARY_USER_CERT_HOLDER',
  BENEFICIARY_USER_CERTS = 'BENEFICIARY_USER_CERTS',
  BENEFICIARY_USER_ROLES = 'BENEFICIARY_USER_ROLES',
  BENEFICIARY_USER_COLLECTED_DATA = 'BENEFICIARY_USER_COLLECTED_DATA', // TODO

  RESERVER_USER_NAME = 'RESERVER_USER_NAME',
  RESERVER_USER_SURNAME = 'RESERVER_USER_SURNAME',
  RESERVER_USER_EMAIL = 'RESERVER_USER_EMAIL',
  RESERVER_USER_COLLECTED_DATA = 'RESERVER_USER_COLLECTED_DATA', // TODO

  SHOP_ITEM_TITLE = 'SHOP_ITEM_TITLE',
  SHOP_ITEM_CODE = 'SHOP_ITEM_CODE',
  SHOP_ITEM_ID = 'SHOP_ITEM_ID',
  SHOP_ITEM_RESERVATION_TYPE = 'SHOP_ITEM_RESERVATION_TYPE',
  SHOP_ITEM_EVENT_TITLE = 'SHOP_ITEM_EVENT_TITLE',
  SHOP_ITEM_EVENT_CODE = 'SHOP_ITEM_EVENT_CODE',
  SHOP_ITEM_START_AT = 'SHOP_ITEM_START_AT',
  SHOP_ITEM_END_AT = 'SHOP_ITEM_END_AT',
  SHOP_ITEM_CATEGORIES = 'SHOP_ITEM_CATEGORIES',
  SHOP_ITEM_RESERVATION_STATE = 'SHOP_ITEM_RESERVATION_STATE',
  SHOP_ITEM_INTERNAL_TAGS = 'SHOP_ITEM_INTERNAL_TAGS',
  SHOP_ITEM_CUSTOM_ITEM_NUMBER = 'SHOP_ITEM_CUSTOM_ITEM_NUMBER',

  RESERVATION_STATE = 'RESERVATION_STATE',
  RESERVATION_CREATED_AT = 'RESERVATION_CREATED_AT',
  RESERVATION_CANCELLED_AT = 'RESERVATION_CANCELLED_AT',
  RESERVATION_APPROVED_AT = 'RESERVATION_APPROVED_AT',
  RESERVATION_CONFIRMED_AT = 'RESERVATION_CONFIRMED_AT',
  RESERVATION_GUEST = 'RESERVATION_GUEST',
  RESERVATION_QUANTITY = 'RESERVATION_QUANTITY',
  RESERVATION_COLLECTED_DATA = 'RESERVATION_COLLECTED_DATA', // TODO
  RESERVATION_ADMIN_NOTE = 'RESERVATION_ADMIN_NOTE',
  RESERVATION_APPLIED_DISCOUNTS = 'RESERVATION_APPLIED_DISCOUNTS', // TODO
  RESERVATION_FINAL_PRICE = 'RESERVATION_FINAL_PRICE',

  ORDER_NUMBER = 'ORDER_NUMBER',
  ORDER_CREATED_AT = 'ORDER_CREATED_AT',
  ORDER_COMPLETED_AT = 'ORDER_COMPLETED_AT',
  ORDER_CANCELLED_AT = 'ORDER_CANCELLED_AT',
  ORDER_MATURITY = 'ORDER_MATURITY',
  ORDER_INVOICE_NUMBER = 'ORDER_INVOICE_NUMBER',
  ORDER_PROFORMA_NUMBER = 'ORDER_PROFORMA_NUMBER',
  ORDER_BILLING_INFO = 'ORDER_BILLING_INFO',
  ORDER_INVOICING_CONTACT = 'ORDER_INVOICING_CONTACT', // TODO
  ORDER_FINAL_PRICE = 'ORDER_FINAL_PRICE',
  ORDER_CUSTOMER_NOTE = 'ORDER_CUSTOMER_NOTE',
  ORDER_CURRENCY = 'ORDER_CURRENCY',

  // TODO: ???
  ORDER_ITEM_PRICE = 'ORDER_ITEM_PRICE',
  ORDER_ITEM_VAT = 'ORDER_ITEM_VAT',

  COMPANY_NUMBER = 'COMPANY_NUMBER',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  COMPANY_EMAIL = 'COMPANY_EMAIL', // TODO
  COMPANY_PHONE = 'COMPANY_PHONE', // TODO
  COMPANY_WEBSITE = 'COMPANY_WEBSITE', // TODO
  COMPANY_CUSTOM_DATA = 'COMPANY_CUSTOM_DATA' // TODO
}

export enum CustomersPersonsTableColumnItemType {
  CUSTOMER_PERSON = 'CUSTOMER_PERSON',
  CUSTOMER_PERSON_EMAIL = 'CUSTOMER_PERSON_EMAIL',
  CUSTOMER_PERSON_COMPANY = 'CUSTOMER_PERSON_COMPANY',
  CUSTOMER_PERSON_CERTIFICATES = 'CUSTOMER_PERSON_CERTIFICATES',
  CUSTOMER_PERSON_CREDIT = 'CUSTOMER_PERSON_CREDIT',
  CUSTOMER_PERSON_ROLES = 'CUSTOMER_PERSON_ROLES',
  CUSTOMER_PERSON_NOTES = 'CUSTOMER_PERSON_NOTES',
  CUSTOMER_PERSON_COLLECTED_DATA = 'CUSTOMER_PERSON_COLLECTED_DATA'
}

export enum CustomersPersonsTableColumnItemGroupType {
  CUSTOMER_PERSON_DATA = 'CUSTOMER_PERSON_DATA'
}

export enum CustomersCompaniesTableColumnItemType {
  CUSTOMER_COMPANY_NAME = 'CUSTOMER_COMPANY_NAME',
  CUSTOMER_COMPANY_NUMBER = 'CUSTOMER_COMPANY_NUMBER',
  CUSTOMER_COMPANY_ADDRESS = 'CUSTOMER_COMPANY_ADDRESS',
  CUSTOMER_COMPANY_EMAIL = 'CUSTOMER_COMPANY_EMAIL',
  CUSTOMER_COMPANY_PHONE = 'CUSTOMER_COMPANY_PHONE',
  CUSTOMER_COMPANY_WEBSITE = 'CUSTOMER_COMPANY_WEBSITE',
  CUSTOMER_COMPANY_COLLECTED_DATA = 'CUSTOMER_COMPANY_COLLECTED_DATA',
}

export enum CustomersCompaniesTableColumnItemGroupType {
  CUSTOMER_COMPANY_DATA = 'CUSTOMER_COMPANY_DATA'
}

@Component({
  selector: 'app-custom-table-column-item',
  standalone: true,
  imports: [
    FormsModule,
    CheckboxModule, TranslateModule
  ],
  templateUrl: './custom-table-column-item.component.html',
  styleUrl: './custom-table-column-item.component.scss'
})
export class CustomTableColumnItemComponent {

  @Input({required: true}) columnItem!: ICustomTableColumnItem;
  @Input({required: true}) selected: boolean | undefined;
  @Input({required: true}) onChange: () => void = () => {};
  @Input({required: true}) group!: CustomTableColumnItemGroupType;
  @Input({required: true}) groupName!: string;

  @Input() showGroup: boolean = false;
  @Input() first: boolean = false;
  @Input() last: boolean = false;

  @Output() checkboxChange = new EventEmitter<{
    e: CheckboxChangeEvent;
    item: ICustomTableColumnItem;
  }>();

  constructor(
    public i18nService: I18nService
  ) {}

  onCheckboxChange(e: CheckboxChangeEvent, columnItem: ICustomTableColumnItem) {
    this.checkboxChange.emit({e, item: columnItem});
  }
}
