import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { IOrder } from 'src/app/shared/models/order/order-model';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { IReservationTableEntity, ReservationTableEntityType } from 'src/app/shared/services/store/org-reservations-store.service';

@Component({
  selector: 'td[app-order-cell]',
  standalone: true,
  imports: [
    TranslateModule,

    NgbTooltipModule,
  ],
  templateUrl: './order-cell.component.html',
  styleUrl: './order-cell.component.scss'
})
export class OrderCellComponent {
  ReservationState = ReservationState;
  ReservationTableEntityType = ReservationTableEntityType;

  _reservation: IReservationTableEntity | undefined;
  get reservation(): IReservationTableEntity | undefined { return this._reservation; };
  @Input() set reservation(val: IReservationTableEntity | undefined) { this._reservation = val; };

  @Output() onOrder = new EventEmitter<IOrder>(); // clicked on order number -> open customer card modal
  @Output() onWaitingForOrder = new EventEmitter<IReservation>(); // clicked on 'waiting on order' text -> open customer card modal with reservations tab

}
