

<ng-container *ngIf="(state.fetchingAttendance$ | async); else notFetching">
  <div class="flex justify-content-center align-items-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-container>

<ng-template #notFetching>

  <ng-container *ngIf="(state.customerCard$ | async)?.attendance as a">

    <div *ngIf="a.excusedForReplacement as excused">
      <p-panel header="{{ 'admin.customer-card-attendance.excused.label' | translate:{cnt:excused.length} }}" [toggleable]="true" [collapsed]="false" toggler="header" class="excused-panel">
        
        <ng-container *ngFor="let item of excused; let last = last;">
          <app-excused-for-replacement-item [item]="item" [userId]="customerCardStore.initData?.userId"></app-excused-for-replacement-item>

          <ng-container *ngIf="!last">
            <div style="border-bottom: 1px solid var(--surface-d); margin: 0 calc(-1 * var(--content-padding)); margin-right: -1px;"></div>
          </ng-container>
        </ng-container>
        
        <ng-template pTemplate="headericons" let-collapsed>
          <ng-container *ngIf="collapsed">
            <i class="pi pi-chevron-down"></i>
          </ng-container>
          <ng-container *ngIf="!collapsed">
            <i class="pi pi-chevron-up"></i>
          </ng-container>
        </ng-template>
      </p-panel>
    </div>
  
    <div *ngIf="a.currentlyAttending as attending" class="mt-2">
      <p-panel header="{{ 'admin.customer-card-attendance.attending.label' | translate:{cnt:attending.length} }}" [toggleable]="true" [collapsed]="false" toggler="header">
        
        <ng-container *ngFor="let item of attending; let last = last;">
          <app-attending-item [item]="item"></app-attending-item>

          <ng-container *ngIf="!last">
            <div style="border-bottom: 1px solid var(--surface-d); margin: 0 calc(-1 * var(--content-padding));"></div>
          </ng-container>
        </ng-container>
        
        <ng-template pTemplate="headericons" let-collapsed>
          <ng-container *ngIf="collapsed">
            <i class="pi pi-chevron-down"></i>
          </ng-container>
          <ng-container *ngIf="!collapsed">
            <i class="pi pi-chevron-up"></i>
          </ng-container>
        </ng-template>
      </p-panel>
    </div>

  </ng-container>

</ng-template>
