

<ng-container *ngIf="reservation">
  <ng-container *ngIf="reservation.state === ReservationState.APPROVED">
    <ng-container *ngIf="reservation.order as o">
      <p class="m-0 mt-1 text-orange-300 text-sm">
        {{ 'admin.reservation-status.waiting-for-payment-label' | translate:{date:o.maturity | localizedDate:'shortDate'} }} 
        <span (click)="onRecordPayment(o.id, reservation.beneficiaryUser!)" class="underline cursor-pointer">
          {{ 'admin.reservation-status.record-payment-label' | translate }}
        </span>
      </p>
    </ng-container>
  
    <ng-container *ngIf="!reservation.order">
      <p class="m-0 mt-1 text-orange-300 text-sm">
        {{ 'admin.reservation-status.waiting-for-finish-order-label' | translate:{date:reservation.expiration | localizedDate:'shortDate'} }}
      </p>
    </ng-container>
  </ng-container>
</ng-container>