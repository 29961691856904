

<div class="flex align-items-center w-full mt-1">

  <!-- BOTH btns -->
  <ng-container *ngIf="invoicesHelper.getShowInvoiceBtn(order) && transfer?.state === TransferState.COMPLETED; else singleBtnEl">
    <div class="grid w-full">
      <div class="col-6">
        <ng-container *ngTemplateOutlet="invoiceBtn"></ng-container>
      </div>
  
      <div class="col-6">
        <ng-container *ngTemplateOutlet="paymentBtn"></ng-container>
      </div>
    </div>
  </ng-container>
  <!-- / -->

  <!-- Single btn -->
  <ng-template #singleBtnEl>
    <div class="{{singleBtnWidthClass}}" style="margin: 0 auto;">
      <ng-container *ngTemplateOutlet="invoiceBtn"></ng-container>

      <ng-container *ngTemplateOutlet="paymentBtn"></ng-container>
    </div>
  </ng-template>
  <!-- / -->
</div>

<ng-template #invoiceBtn>
  <ng-container *ngIf="invoicesHelper.getShowInvoiceBtn(order)" >
    <button
      (click)="onShowOrderInvoice()"
      pButton
      label="{{'payment-gate-payment-status.show-order-invoice-btn-label' | translate}}"
      class="p-button-dark-blue p-button-outlined w-full">
    </button>
  </ng-container>
</ng-template>

<ng-template #paymentBtn>
  <ng-container *ngIf="transfer?.state === TransferState.COMPLETED">
    <button
      (click)="onGoToCustomerPayment(transfer!.uuid)"
      pButton
      label="{{'payment-gate-payment-status.show-payment-btn-label' | translate}}"
      class="p-button-dark-blue w-full">
    </button>
  </ng-container>
</ng-template>