<app-modal-header
  (close)="close()">
  <span slot="title">
    {{'admin.form-field-settings-modal.title' | translate }}    
  </span>
</app-modal-header>


<div [formGroup]="form" class="modal-body align-items-center" [class.loading-box]="loading">

  <div class="p-inputgroup col-12">
    <span class="p-inputgroup-addon">{{'admin.form-field-settings-modal.itemtitle' | translate }}</span>
    <input type="text" pInputText formControlName="title" class="w-full" style="font-weight: bold;"/>
  </div>
  <small *ngIf="form.controls.title.hasError('title')" class="pl-2 -mt-2 p-error">{{'admin.form-field-settings-modal.alreadyexists' | translate }}</small>

  <div class="col-12">
    <span class="p-float-label field">
      <input id="description" formControlName="description" type="text" pInputText class="w-full">
      <label for="description">{{'admin.form-field-settings-modal.shortdescription' | translate }}</label>
    </span>
  </div>

  <div class="col-12">
    <p-divider align="left">
      <div class="inline-flex">
        <b>{{'admin.form-field-settings-modal.settings' | translate }}</b>
      </div>
    </p-divider>
  </div>

  <div class="col-12">
    <p-dropdown
      formControlName="dataType"
      [options]="dataTypesOptions"
      optionLabel="label"
      optionValue="value"
      placeholder="{{'admin.form-field-settings-modal.selectdatatype' | translate }}"
      class="w-full"
      styleClass="w-full">
    </p-dropdown>
  </div>

  <div class="col-12 flex justify-content-end column-gap-2 pb-0" *ngIf="dataTypeFC.value !== DataType.READONLY_TEXT">
    <span [class.text-primary]="requiredFC.value">
      {{ requiredFC.value ? ('admin.form-field-settings-modal.required' | translate) : ('admin.form-field-settings-modal.optional' | translate) }}
    </span>
    
    <p-inputSwitch formControlName="required" />
  </div>

  <div *ngIf="dataTypeFC.value === DataType.CHECKBOX || dataTypeFC.value === DataType.TRI_STATE_CHECKBOX || dataTypeFC.value === DataType.CONSENT" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <span class="p-float-label field">
      <input id="link" formControlName="link" type="text" pInputText class="w-full">
      <label for="link">{{'admin.form-field-settings-modal.url' | translate }}</label>
    </span>
  </div>

  <div *ngIf="dataTypeFC.value === DataType.MULTISELECT" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <span>{{'DataType.MULTISELECT.description' | translate }}</span>
    <textarea rows="3" pInputTextarea [autoResize]="true" formControlName="multiSelectItems" class="w-full"></textarea>
  </div>

  <div *ngIf="selectionLimitFC" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <span>{{'admin.form-field-settings-modal.selection-limit' | translate }}</span>
    <p-selectButton [options]="selectionLimitOptions" [allowEmpty]="false" formControlName="selectionLimit" optionLabel="label" optionValue="value"></p-selectButton>
  </div>

  <div class="col-12 flex gap-2" *ngIf="otherOptionFC" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <p-checkbox formControlName="otherOption" [binary]="true" inputId="otherOption"></p-checkbox>
    <label for="otherOption">
      <span>{{'admin.form-field-settings-modal.other-option' | translate }}</span>
    </label>
  </div>

  <div *ngIf="dataTypeFC.value === DataType.SCROLLING_TEXTAREA" class="col-12" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <span>{{'DataType.SCROLLING_TEXTAREA.description' | translate }}</span>
    <p-editor #ed formControlName="scrollingContent" placeholder="{{'DataType.SCROLLING_TEXTAREA.placeholder' | translate }}" [modules]="{clipboard:{matchVisual:false}}" class="-mt-2" [style]="{'height':'8rem'}">
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-underline" aria-label="Underline"></button>
          <button type="button" class="ql-strike" aria-label="Strike"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button aria-label="Link" class="ql-link"></button>
        </span>
        <span class="ql-formats">
          <button (click)="ed.getQuill().removeFormat(ed.getQuill().getSelection().index,ed.getQuill().getSelection().length)" class="cust-clear" title="{{'admin.wysiwyg.clearFormating' | translate }}"><i class="pi pi-eraser text-lg font-medium text-color-secondary" style="margin-top: 0.102rem;"></i></button>
        </span>
      </ng-template>
    </p-editor>
  </div>

  <div *ngIf="dataTypeFC.value === DataType.READONLY_TEXT" class="col-12">
    <span>{{'DataType.READONLY_TEXT.description' | translate }}</span>
    <p-editor #ed_rc formControlName="readonlyContent" placeholder="{{'DataType.READONLY_TEXT.placeholder' | translate }}" [modules]="{clipboard:{matchVisual:false}}" class="-mt-2" [style]="{'height':'8rem'}">
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-underline" aria-label="Underline"></button>
          <button type="button" class="ql-strike" aria-label="Strike"></button>
        </span>
        <!-- <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span> -->
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button aria-label="Link" class="ql-link"></button>
        </span>
        <span class="ql-formats">
          <button (click)="ed_rc.getQuill().removeFormat(ed_rc.getQuill().getSelection().index,ed_rc.getQuill().getSelection().length)" class="cust-clear" title="{{'admin.wysiwyg.clearFormating' | translate }}"><i class="pi pi-eraser text-lg font-medium text-color-secondary" style="margin-top: 0.102rem;"></i></button>
        </span>
      </ng-template>
    </p-editor>
  </div>

  <div class="col-12 flex gap-2" *ngIf="useAsPrivacyPolicyFC">
    <p-checkbox formControlName="useAsPrivacyPolicy" [binary]="true" inputId="useAsPrivacyPolicy"></p-checkbox>
    <label for="useAsPrivacyPolicy">
      <span>{{'admin.form-field-settings-modal.useAsPrivacyPolicy' | translate }}</span>
    </label>
  </div>

  <div class="col-12 flex gap-2" *ngIf="useAsTOSFC">
    <p-checkbox formControlName="useAsTOS" [binary]="true" inputId="useAsTOS"></p-checkbox>
    <label for="useAsTOS">
      <span>{{'admin.form-field-settings-modal.useAsTOS' | translate }}</span>
    </label>
  </div>

  <div class="col-12 flex gap-2" *ngIf="useAsBillingInfoFC">
    <p-checkbox formControlName="useAsBillingInfo" [binary]="true" inputId="useAsBillingInfo"></p-checkbox>
    <label for="useAsBillingInfo">
      <span>{{'admin.form-field-settings-modal.useAsBillingInfo' | translate }}</span>
    </label>
  </div>

  <ng-container *ngIf="dataTypeFC.value === DataType.DATE || dataTypeFC.value === DataType.BIRTHDATE">
    <div class="col-12 pt-0 pb-0">
      <p-divider align="left">
        <div class="inline-flex">
          <b>{{'admin.form-field-settings-modal.conditions' | translate }}</b>
        </div>
      </p-divider>
    </div>

    <!-- TODO: do it dynamically -->
    <div class="p-inputgroup col-12">
      <span class="p-inputgroup-addon">{{ AcceptationRequirementTemplateCodeName.get(acceptationRequirementsFA.controls[0].controls.templateCode.value) }}</span>
      <p-inputNumber
        appNumberInput
        placeholder="{{'admin.form-field-settings-modal.yearsCount' | translate }}"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="5"
        [formControl]="acceptationRequirementsFA.controls[0].controls.value"
      />
      <span class="p-inputgroup-addon"><p-checkbox [binary]="true" [formControl]="acceptationRequirementsFA.controls[0].controls.active"></p-checkbox></span>
    </div>

    <div class="p-inputgroup col-12">
      <span class="p-inputgroup-addon">{{ AcceptationRequirementTemplateCodeName.get(acceptationRequirementsFA.controls[1].controls.templateCode.value) }}</span>
      <p-inputNumber
        appNumberInput
        placeholder="{{'admin.form-field-settings-modal.yearsCount' | translate }}"
        mode="decimal"
        [minFractionDigits]="0"
        [maxFractionDigits]="5"
        [formControl]="acceptationRequirementsFA.controls[1].controls.value"
      />
      <span class="p-inputgroup-addon"><p-checkbox [binary]="true" [formControl]="acceptationRequirementsFA.controls[1].controls.active"></p-checkbox></span>
    </div>

  </ng-container>

  <div class="col-12 pt-0">
    <p-divider></p-divider>
  </div>

  <div class="col-12">
    <div class="flex gap-3 surface-border border-1 border-round p-3 cursor-pointer">
      <p-checkbox formControlName="isUserData" [binary]="true" inputId="isUserData"></p-checkbox>
      <label for="isUserData" class="flex flex-column cursor-pointer gap-2">
        <span class="font-bold">{{'admin.form-field-settings-modal.includeUserData' | translate }}</span>
        <span>{{'admin.form-field-settings-modal.includeUserData.briefdescription' | translate }}</span>
        <span>{{'admin.form-field-settings-modal.includeUserData.longdescription' | translate }}</span>
      </label>
    </div>
  </div>

  <div *ngIf="form.controls.isUserData.value" class="col-12 flex justify-content-between align-items-center" [ngbTooltip]="showUserDataDisabledTooltip ? ('admin.form-field-settings-modal.user-data-disabled.tooltip' | translate) : null" container="body">
    <span>{{'admin.form-field-settings-modal.validityTime' | translate }}</span>
    <p-inputNumber
      appNumberInput
      placeholder="{{'admin.form-field-settings-modal.daycount' | translate }}"
      [formControl]="form.controls.expirationDays"
      [min]="1"
      [suffix]="' ' + (daysString | translate)"
    />
  </div>
</div>

<div class="modal-footer">
  <button
    class="w-full"
    [loading]="saving"
    [disabled]="saving"
    (click)="onSubmit()"
    pButton
    label="{{'admin.form-field-settings-modal.save' | translate }}">
  </button>
</div>