<app-modal-header
  (close)="close()">
  <span slot="breadcrumb">
    {{ 'admin.change-beneficiary-modal.breadcrumb' | translate }}
  </span>
  <span slot="title">
    {{ shopItem.title }}
  </span>
</app-modal-header>

<div class="modal-body flex flex-column row-gap-2" [formGroup]="form">
  <p-autoComplete
    [minLength]="3"
    [autoHighlight]="true"
    [suggestions]="userSuggestions"
    field="selectedUser"
    formControlName="email"
    placeholder="{{ 'admin.change-beneficiary-modal.user-search.placeholder' | translate }}"
    (onSelect)="onUserSuggestionSelect($event)"
    (completeMethod)="whisper($event)"
    [completeOnFocus]="true"
    styleClass="w-full"
    inputStyleClass="w-full">
    <ng-template let-user pTemplate="item">
      {{ user.label }}
    </ng-template>
  </p-autoComplete>

  <input placeholder="{{ 'admin.change-beneficiary-modal.user-name.placeholder' | translate }}" type="text" pInputText formControlName="userName" />

  <p-dropdown
    formControlName="preferredLanguage" 
    [options]="preferredLanguageOptions"
    optionLabel="label"
    optionValue="value"
    placeholder="{{ 'admin.change-beneficiary-modal.preferred-language.placeholder' | translate }}">
    <ng-template let-item pTemplate="selectedItem">
      <div>{{ item.label | translate }}</div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div>{{ item.label | translate }}</div>
    </ng-template>
  </p-dropdown>

  <div [class.loading-box]="fetchingUserData">
    <app-custom-data-inputs [customDataFG]="customDataFG" [userData]="userData"></app-custom-data-inputs>
  </div>
</div>

<div class="modal-footer justify-content-between">

  <button (click)="close()" pButton label="{{'admin.change-beneficiary-modal.close' | translate }}" class="p-button-text p-button-plain"></button>
  <button (click)="onSubmit()" pButton label="{{'admin.change-beneficiary-modal.submit' | translate }}" icon="pi pi-check" class="p-button-success"></button>

</div>
