import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule, getCurrencySymbol, PercentPipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TimelineModule } from 'primeng/timeline';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { IReservation } from '../../models/reservation/reservation.model';
import { IOrder, IOrderFE } from '../../models/order/order-model';
import { ButtonModule } from 'primeng/button';
import { Modal } from '../modal';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { IShopItem, ShopItemApprovalType } from '../../models/shop-item/shop-item.model';
import { collapseAnimation } from '../../animations/collapse.animation';
import { OrderNumberPipe } from '../../pipes/order-number.pipe';
import { OrderState } from '../../enums/order/order-states.enum';
import { Subscription, take } from 'rxjs';
import { UserNameOrEmailPipe } from '../../pipes/user-name-or-email.pipe';
import { UtilsService } from '../../services/utils.service';
import { OrdersService } from '../../services/entities/orders/orders.service';
import { ReservationState } from '../../enums/reservation/reservation-states.enum';
import { TranslateModule } from '@ngx-translate/core';
import { NumberInputDirective } from '../../directives/number-input.directive';
import { SelectItem } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DividerModule } from 'primeng/divider';
import { ReservationsService } from '../../services/entities/reservations/reservations.service';
import { PricePipe } from '../../pipes/price.pipe';
import { Currency } from '../../enums/price/currencies.enum';
import { IPrice } from '../../models/price/price.model';
import { IUser } from '../../models/user/user.model';

enum TimelineItemType {
  CANCEL_RESERVATION = 'CANCEL_RESERVATION',
  CANCEL_PARTNER_RESERVATION = 'CANCEL_PARTNER_RESERVATION',
  WAITING_LIST = 'WAITING_LIST',
  DISCOUNTS = 'DISCOUNTS',
  CANCEL_ORDER = 'CANCEL_ORDER',
  REFUND = 'REFUND',
  REFUND_PARTNER = 'REFUND_PARTNER',
  NOTIFICATION = 'NOTIFICATION'
}

interface IRefundFG {
  totalPaid: FormControl<number>;
  itemPrice: FormControl<IPrice>;
  totalPaidPercentage: FormControl<number>;
  lostDiscounts: FormControl<number>;
  stornoFee: FormControl<number | null>;
  refundAmount: FormControl<number>;
  refundToCredit: FormControl<boolean>;
  currency: FormControl<Currency>;
}

interface ICancelReservationForm extends Partial<IRefundFG> {
  sendNotifications: FormControl<boolean>;
  partnerRefundFG?: FormGroup<IRefundFG>;
}

export interface ICancelReservationModalResult {
  sendNotifications: boolean;
  stornoFee: number;
  refundToCredit: boolean;
  partner: {
    reservationId: number;
    stornoFee: number;
    refundToCredit: boolean;
  } | null;
}

@Component({
  selector: 'app-cancel-reservation-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TimelineModule, TranslateModule,
    InputNumberModule, CheckboxModule, ButtonModule,
    ModalHeaderComponent, NumberInputDirective,
    OrderNumberPipe, UserNameOrEmailPipe, RadioButtonModule, DividerModule, PricePipe, PercentPipe
  ],
  templateUrl: './cancel-reservation-modal.component.html',
  styleUrls: ['./cancel-reservation-modal.component.scss'],
  animations: [ collapseAnimation ]
})
export class CancelReservationModalComponent extends Modal implements OnInit, OnDestroy {
  TimelineItemType = TimelineItemType;
  getCurrencySymbol = getCurrencySymbol;
  Currency = Currency;

  @Input() shopItem: IShopItem | undefined;
  @Input() reservation: IReservation | undefined;
  @Input() order: IOrder | undefined;

  @Output() result = new EventEmitter<ICancelReservationModalResult>();

  timelineItems: TimelineItemType[] = [];
  timelineCollapsedMap: {
    [key: string]: boolean
  } = Object.keys(TimelineItemType).reduce((prev, curr) => ({ ...prev, [curr]: (curr !== TimelineItemType.REFUND) && (curr !== TimelineItemType.REFUND_PARTNER) }), {});

  refundOptions: SelectItem[] = [
    {
      value: true,
      title: 'admin.cancel-reservation-modal.TimelineItemType.REFUND.refundToCredit.title',
      label: 'admin.cancel-reservation-modal.TimelineItemType.REFUND.refundToCredit.description'
    },
    {
      value: false,
      title: 'admin.cancel-reservation-modal.TimelineItemType.REFUND.refundToUser.title',
      label: 'admin.cancel-reservation-modal.TimelineItemType.REFUND.refundToUser.description'
    }
  ];

  form: FormGroup<ICancelReservationForm> = new FormGroup({
    sendNotifications: new FormControl<boolean>(true, { nonNullable: true }),
  });

  loading = false;

  emails: string = '';

  private subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService,
    private reservationService: ReservationsService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.reservation) {
      this.loading = true;
      this.reservationService.getCancellationSummary(this.reservation.id).pipe(take(1)).subscribe((res) => {
        this.addTimelineItems(res.info.refund, res.partnerInfo?.refund || false);
        // Check how many not-cancelled reservations order has, if there's more than one, remove CANCEL_ORDER from timelineItems arr
        if (res.reservationsCnt === 1) {
          const x = this.order?.state === OrderState.COMPLETED ? -2 : -1;
          this.timelineItems.splice(this.timelineItems.length + x, 0, TimelineItemType.CANCEL_ORDER);
        }

        if (res.info.refund) {
          const lostDiscountsValue = res.info.discountChange?.value || 0;
          this.form.addControl('totalPaid', new FormControl<number>({value: res.info.totalPaid?.value || 0, disabled: true}, { nonNullable: true }));
          this.form.addControl('stornoFee', new FormControl<number | null>(null, { validators: [Validators.required], nonNullable: true }));
          this.form.addControl('refundAmount', new FormControl<number>({value: (res.info.totalPaid?.value || 0) - lostDiscountsValue, disabled: true}, { nonNullable: true }));
          this.form.addControl('refundToCredit', new FormControl<boolean>(true, { nonNullable: true }));
          this.form.addControl('lostDiscounts', new FormControl<number>(lostDiscountsValue, { nonNullable: true }));
          this.form.addControl('totalPaidPercentage', new FormControl<number>(res.info.totalPaidPercentage, { nonNullable: true }));
          this.form.addControl('itemPrice', new FormControl<IPrice>(res.info.itemPrice!, { nonNullable: true }));
          this.form.addControl('currency', new FormControl<Currency>(res.info.orderCurrency, { nonNullable: true }));

          this.subs.push(
            this.form.controls.stornoFee!.valueChanges.subscribe((stornoFee) => {
              this.form.controls.refundAmount!.patchValue(this.form.controls.totalPaid!.value - (stornoFee || 0) - lostDiscountsValue);
            }) || Subscription.EMPTY
          );
        }

        if (res.partnerInfo?.refund) {
          const lostDiscountsValue = res.partnerInfo.discountChange?.value || 0;
          const partnerRefundFG = new FormGroup<IRefundFG>({
            totalPaid: new FormControl<number>({value: res.partnerInfo.totalPaid?.value || 0, disabled: true}, { nonNullable: true }),
            stornoFee: new FormControl<number | null>(null, { validators: [Validators.required], nonNullable: true }),
            refundAmount: new FormControl<number>({value: (res.partnerInfo.totalPaid?.value || 0) - lostDiscountsValue, disabled: true}, { nonNullable: true }),
            refundToCredit: new FormControl<boolean>(true, { nonNullable: true }),
            lostDiscounts: new FormControl<number>(lostDiscountsValue, { nonNullable: true }),
            totalPaidPercentage: new FormControl<number>(res.partnerInfo.totalPaidPercentage, { nonNullable: true }),
            itemPrice: new FormControl<IPrice>(res.partnerInfo.itemPrice!, { nonNullable: true }),
            currency: new FormControl<Currency>(res.partnerInfo.orderCurrency, { nonNullable: true })
          });

          this.subs.push(
            partnerRefundFG.controls.stornoFee!.valueChanges.subscribe((stornoFee) => {
              partnerRefundFG.controls.refundAmount!.patchValue(partnerRefundFG.controls.totalPaid!.value - (stornoFee || 0) - lostDiscountsValue);
            }) || Subscription.EMPTY
          );

          this.form.addControl('partnerRefundFG', partnerRefundFG);
        }

        this.loading = false;
      });
    } else {
      this.addTimelineItems(false, false);
    }
    this.emails = [...new Set([this.reservation?.reserverUser?.email, this.reservation?.partnerReservation?.reserverUser?.email].filter((x) => x))].join(', ');
  }

  onItem(itemType: TimelineItemType) {
    this.timelineCollapsedMap[itemType] = !this.timelineCollapsedMap[itemType];
  }

  onSendNotificationClick(event: Event) {
    event.stopPropagation();
  }

  private addTimelineItems(refund: boolean, partnerRefund: boolean) {
    this.timelineItems.push(TimelineItemType.CANCEL_RESERVATION);
    if (this.reservation?.partnerReservationId) this.timelineItems.push(TimelineItemType.CANCEL_PARTNER_RESERVATION);
    if (this.shopItem?.approvalType !== ShopItemApprovalType.MANUAL) this.timelineItems.push(TimelineItemType.WAITING_LIST);
    this.timelineItems.push(TimelineItemType.DISCOUNTS);
    if (partnerRefund) {
      this.timelineItems.push(TimelineItemType.REFUND_PARTNER);
    }
    if (refund) {
      this.timelineItems.push(TimelineItemType.REFUND);
    }
    this.timelineItems.push(TimelineItemType.NOTIFICATION);

    this.timelineItems = [...this.timelineItems];
  }

  public onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid) {
      if (this.form.controls.stornoFee?.invalid) {
        this.timelineCollapsedMap[TimelineItemType.REFUND] = false;
      }
      if (this.form.controls.partnerRefundFG?.controls.stornoFee?.invalid) {
        this.timelineCollapsedMap[TimelineItemType.REFUND_PARTNER] = false;
      }
      console.error('invalid');
      return;
    }
    this.result.emit({
      sendNotifications: this.form.controls.sendNotifications.value,
      stornoFee: this.form.controls.stornoFee?.value || 0,
      refundToCredit: this.form.controls.refundToCredit?.value || false,
      partner: this.form.controls.partnerRefundFG && this.reservation?.partnerReservationId
        ? {
          reservationId: this.reservation.partnerReservationId,
          refundToCredit: this.form.controls.partnerRefundFG.controls.refundToCredit.value || false,
          stornoFee: this.form.controls.partnerRefundFG.controls.stornoFee.value || 0
        }
        : null
    });
    this.close();
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
