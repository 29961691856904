import { Injectable } from '@angular/core';
import { IReservation } from '../../models/reservation/reservation.model';
import { IOrder } from '../../models/order/order-model';
import { IOffer } from '../../models/offer/offer.model';
import { OfferState } from '../../enums/offer/offer-states.enum';
import { OrderState } from '../../enums/order/order-states.enum';
import { UtilsService } from '../utils.service';
import { ReservationState } from '../../enums/reservation/reservation-states.enum';

export enum ReservationCurrentState {
  // poptavky
  OFFER_NEW = 'OFFER_NEW',
  OFFER_IN_PREPARATION = 'OFFER_IN_PREPARATION',
  OFFER_CANCELLED = 'OFFER_CANCELLED',
  OFFER_ISSUED = 'OFFER_ISSUED',
  OFFER_ISSUED__ORDER_PENDING = 'OFFER_ISSUED__ORDER_PENDING',
  OFFER_CONFIRMED = 'OFFER_CONFIRMED',

  // normalni rezervace (rezervace je v objednavce)
  ORDER_PENDING = 'ORDER_PENDING',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
  ORDER_AWAITING_ON_SITE_PAYMENT = 'ORDER_AWAITING_ON_SITE_PAYMENT',
  ORDER_PARTIALLY_COMPLETED = 'ORDER_PARTIALLY_COMPLETED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',

  // normalni rezervace (rezervace neni v objednavce)
  RESERVATION_SUBMITTED = 'RESERVATION_SUBMITTED',
  RESERVATION_APPROVED = 'RESERVATION_APPROVED',
  RESERVATION_WAITING = 'RESERVATION_WAITING',
  RESERVATION_CANCELLED = 'RESERVATION_CANCELLED',
  RESERVATION_CONFIRMED = 'RESERVATION_CONFIRMED',
};
export const ReservationCurrentStateColorMap = new Map<ReservationCurrentState, string>([
  [ ReservationCurrentState.OFFER_NEW, '--primary-color' ],
  [ ReservationCurrentState.OFFER_IN_PREPARATION, '--primary-color' ],
  [ ReservationCurrentState.OFFER_CANCELLED, '--text-color-secondary' ],
  [ ReservationCurrentState.OFFER_ISSUED, '--primary-color' ],
  [ ReservationCurrentState.OFFER_ISSUED__ORDER_PENDING, '--l-warn' ],
  [ ReservationCurrentState.OFFER_CONFIRMED, '--l-success' ],
  [ ReservationCurrentState.ORDER_PENDING, '--l-warn' ],
  [ ReservationCurrentState.ORDER_COMPLETED, '--l-success' ],
  [ ReservationCurrentState.ORDER_AWAITING_ON_SITE_PAYMENT, '--l-success' ],
  [ ReservationCurrentState.ORDER_PARTIALLY_COMPLETED, '--cyan-400' ],
  [ ReservationCurrentState.ORDER_CANCELLED, '--text-color-secondary' ],
  [ ReservationCurrentState.RESERVATION_SUBMITTED, '--cyan-400' ],
  [ ReservationCurrentState.RESERVATION_APPROVED, '--primary-color' ],
  [ ReservationCurrentState.RESERVATION_WAITING, '--indigo-400' ],
  [ ReservationCurrentState.RESERVATION_CANCELLED, '--text-color-secondary' ],
  [ ReservationCurrentState.RESERVATION_CONFIRMED, '--l-success' ],
]);

@Injectable({
  providedIn: 'root'
})
export class ReservationHelperService {

  constructor(
    private utils: UtilsService,
  ) { }

  public getCurrentState(r: IReservation & { order?: IOrder | null, offer?: IOffer | null }): ReservationCurrentState {
    // start with offer
    if (r.offer) {
      switch (r.offer.state) {
        case OfferState.NEW:
          return ReservationCurrentState.OFFER_NEW;
        case OfferState.IN_PREPARATION:
          return ReservationCurrentState.OFFER_IN_PREPARATION;
        case OfferState.CANCELLED:
          return ReservationCurrentState.OFFER_CANCELLED;
        case OfferState.ISSUED:
          if (r.order?.state === OrderState.PENDING) return ReservationCurrentState.OFFER_ISSUED__ORDER_PENDING;
          else return ReservationCurrentState.OFFER_ISSUED;
        case OfferState.CONFIRMED:
          return ReservationCurrentState.OFFER_CONFIRMED;
        default:
          this.utils.logError(`Unknown offer state: ${r.offer.state}`, '1209urekk');
          throw new Error(`Unknown offer state: ${r.offer.state}`);
      }
    }
    // no offer => basic reservation
    else {
      // reservation in order
      if (r.order && r.state !== ReservationState.CANCELLED) {
        switch (r.order.state) {
          case OrderState.PENDING:
            return ReservationCurrentState.ORDER_PENDING;
          case OrderState.COMPLETED:
            return ReservationCurrentState.ORDER_COMPLETED;
          case OrderState.AWAITING_ON_SITE_PAYMENT:
            return ReservationCurrentState.ORDER_AWAITING_ON_SITE_PAYMENT;
          case OrderState.PARTIALLY_COMPLETED:
            return ReservationCurrentState.ORDER_PARTIALLY_COMPLETED;
          case OrderState.CANCELLED:
            return ReservationCurrentState.ORDER_CANCELLED;
          default:
            this.utils.logError(`Unknown order state: ${r.order.state}`, '1209urekk');
            throw new Error(`Unknown order state: ${r.order.state}`);
        }
      }
      // reservation not in order
      else {
        switch (r.state) {
          case ReservationState.SUBMITTED:
            return ReservationCurrentState.RESERVATION_SUBMITTED;
          case ReservationState.APPROVED:
            return ReservationCurrentState.RESERVATION_APPROVED;
          case ReservationState.WAITING:
            return ReservationCurrentState.RESERVATION_WAITING;
          case ReservationState.CANCELLED:
            return ReservationCurrentState.RESERVATION_CANCELLED;
          case ReservationState.CONFIRMED:
            return ReservationCurrentState.RESERVATION_CONFIRMED;
          default:
            this.utils.logError(`Unknown reservation state: ${r.state}`, '1209urekk');
            throw new Error(`Unknown reservation state: ${r.state}`);
        }
      }
    }
  }
}
