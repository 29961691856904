
@if (price.length > 1) {
  @if (type === CurrencyChangerType.SELECT_BUTTON) {
    <p-selectButton
      [options]="options"
      [ngModel]="currency"
      optionLabel="label"
      optionValue="value"
      (ngModelChange)="onCurrencySelect($event)"
      styleClass="w-full"
    ></p-selectButton>
  } 
  
  @if (type === CurrencyChangerType.ICON) {
    <span #iconEl (click)="d.show()" class="cursor-pointer" [class.hidden]="type !== CurrencyChangerType.ICON">
      <i class="pi pi-sort-alt rotate-90 text-400">
        <p-dropdown
          #d
          [options]="options"
          [ngModel]="currency"
          (ngModelChange)="onCurrencySelect($event)"
          styleClass=""
          class="icon-dropdown"
          appendTo='body'>
        </p-dropdown>
      </i>
    </span>
  }
  
  @if (type === CurrencyChangerType.DROPDOWN) {
    <p-dropdown
      [options]="options"
      [ngModel]="currency"
      (ngModelChange)="onCurrencySelect($event)"
      styleClass="w-full"
      appendTo='body'>
    </p-dropdown>
  }

}