import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'itemTitle',
  standalone: true,
})
export class ItemTitlePipe implements PipeTransform {

  constructor(
  ) {}

  transform(title?: string | null, count?: number | null): Observable<string> {
    if (!title) return of('');
    if (!count || count === 1) {
      return of(title);
    } else {
      return of(`(${count}x) ${title}`);
    }
  }
}
