
<div>
  <span class="p-float-label">
    <p-dropdown
      [options]="countries"
      [formControl]="countryCodeFC"
      optionLabel="name"
      optionValue="countryCode"
      [filter]="true"
      placeholder="{{ 'admin.country-dropdown-input.placeholder' | translate }}"
      [filterFields]="['name']"
      class="w-full"
      (onChange)="onCountryChange($event)"
      styleClass="w-full">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="countryFC.value">
          <div>{{ countryFC.value }}</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ country.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
    @if (floatLabel) {
      <label for="country">{{ 'admin.country-dropdown-input.label' | translate }}</label>
    }
  </span>
</div>
