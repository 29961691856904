


<ng-container *ngIf="discount && orderItem">

  <div class="flex">
    <div class="flex-1">
      <h5 class="m-0 mb-1">
        {{ discount.title }}
      </h5>

      <div class="info">
        <div class="details-block-wrap ">
          <span class="detail-block">
            {{ discount.percentValue | percent }}
          </span>
          
          <span *ngIf="discount.previousPurchase" class="detail-block">
            {{ 'discount.previous-purchase' | translate }}
          </span>
        </div>
      </div>
  
    </div>
    
    <div class="flex flex-column">
      <span class="font-bold flex flex-column align-items-center justify-content-end">
        <span [class.line-through]="orderItem?.reservation?.state === ReservationState.CANCELLED">
          {{ discount.discountValue | price:orderItem.currency:true | async }}
        </span>
        @if (orderItem.vat) {
          <span class="text-xs text-bluegray-200 align-self-end">
            {{ 'customer-orders.orders.item.including-vat-label' | translate }}
          </span>
        }
      </span>
  
      <span *ngIf="menuItems.length" class="flex justify-content-end">
        <button
          (click)="menu.toggle($event)"
          pButton
          icon="pi pi-ellipsis-h"
          class="p-button-rounded p-button-text p-button-plain p-button-sm"
        ></button>
        <p-menu
          #menu
          [model]="menuItems"
          [popup]="true"
          [baseZIndex]="1000"
          appendTo="body">
        </p-menu>
      </span>
    </div>
  </div>

</ng-container>
